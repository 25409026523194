import { createContext, useContext, useState, useEffect } from "react";
import useForceUpdate from "../hooks/useForceUpdate";

const PlenaConnectContext = createContext({
  chain: "",
  connectWallet: () => {}
});

export const usePlenaConnect = () => useContext(PlenaConnectContext);


const PlenaConnectProvider = ({ children }) => {
  const forceUpdate = useForceUpdate();
  const [chain, setChain] = useState("");



  const connectWallet = async (chainId) => {
     setChain(chainId)
     localStorage.setItem("PlenaChain", chainId);
  };

  const value = {
    chain,
    connectWallet
  };

  useEffect(() => {
    let chainId = localStorage.getItem("PlenaChain");  
    setChain(parseInt(chainId));
  });


  return (
    <>
      <PlenaConnectContext.Provider value={value}>
        {children}
      </PlenaConnectContext.Provider>
    </>
  );
};

export default PlenaConnectProvider;
