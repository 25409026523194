import ABI from "../ABI/dex.json";
import web3 from "web3";
import { getPriceInUSD } from "./axios";
import { toast } from "react-toastify";
import { returnInjectedProvider } from "../helpers/activateinjectedprovider";

let reciever = process.env.REACT_APP_TOKEN_RECIVER_ADDRESS;

export const convertAmount = (amount) => {
  amount = Number(amount ? amount : 0);
  return web3.utils.toWei(amount.toString(), "ether");
};

export let TransferToken = async (
  amount,
  tokenAddress,
  account,
  DexAddress,
  chainID,
  freeTX
) => {
  try {
    let { payable } = await getPriceInUSD(chainID, freeTX); 
    let eth_provider = window.ethereum;
    let provider = localStorage.getItem("Provider"); 
    eth_provider = await returnInjectedProvider(provider);
    if(eth_provider=== undefined) eth_provider = window.ethereum;
    let Web3 = new web3(eth_provider);
    let contract = new Web3.eth.Contract(ABI, DexAddress);
    payable = payable.toFixed(5).toString();
    payable = Web3.utils.toWei(payable, "ether");

    const estimatedGas = await contract.methods
    .depositERC20(tokenAddress, amount, reciever)
    .estimateGas({ from: account, value: payable })

    const gasPrice = await web3.eth?.getGasPrice();

    let transaction = await contract.methods
      .depositERC20(tokenAddress, amount, reciever)
      .send({
        from: account,
        value: payable,
        gas: estimatedGas,
        gasPrice: gasPrice
      });

    return {
      status: true,
      transactionData: transaction.transactionHash,
    };
  } catch (error) {
    console.log(error);
    if (
      error.message ===
      "MetaMask Tx Signature: User denied transaction signature"
    ) {
      toast.error(error.message);
    } else {
      toast.error("Something went wrong. Transfer Ended");
    }
    return { status: false, message: error.message };
  }
};

export let TransferNFTERC721 = async (
  tokenid,
  tokenAddress,
  account,
  DexAddress,
  chainID,
  freeTX
) => {
  try {
    let { payable } = await getPriceInUSD(chainID, freeTX); 
    let eth_provider = window.ethereum;
    let provider = localStorage.getItem("Provider"); 
    eth_provider = await returnInjectedProvider(provider);
    if(eth_provider=== undefined) eth_provider = window.ethereum;
    let Web3 = new web3(eth_provider);
    let contract = new Web3.eth.Contract(ABI, DexAddress);
    payable = payable.toFixed(5).toString();
    payable = Web3.utils.toWei(payable, "ether");
    tokenid = parseInt(tokenid);


   let transaction = await contract.methods
      .depositERC721(tokenAddress, tokenid, reciever)
      .send({
        from: account,
        value: payable
      });

    return {
      status: true,
      transactionData: transaction.transactionHash,
    };
  } catch (error) {
    console.log(error);
    if (
      error.message ===
      "MetaMask Tx Signature: User denied transaction signature"
    ) {
      toast.error(error.message);
    } else {
      toast.error("Something went wrong.");
    }
    return { status: false, message: error.message };
  }
};

export let TransferNFTERC1155 = async (
  tokenid,
  tokenAddress,
  account,
  DexAddress,
  chainID,
  freeTX
) => {
  try {
    let { payable } = await getPriceInUSD(chainID, freeTX); 
    let eth_provider = window.ethereum;
    let provider = localStorage.getItem("Provider"); 
    eth_provider = await returnInjectedProvider(provider);
    if(eth_provider=== undefined) eth_provider = window.ethereum;
    let Web3 = new web3(eth_provider);
    let contract = new Web3.eth.Contract(ABI, DexAddress);
    payable = Web3.utils.toWei(payable.toFixed(5).toString(), "ether");
    tokenid = parseInt(tokenid);

    let transaction = await contract.methods
      .depositERC1155(tokenAddress, tokenid, 1, reciever)
      .send({
        from: account,
        value: payable
      });

    return {
      status: true,
      transactionData: transaction.transactionHash,
    };
  } catch (error) {
    console.log(error);
    if (
      error.message ===
      "MetaMask Tx Signature: User denied transaction signature"
    ) {
      toast.error(error.message);
    } else {
      toast.error("Something went wrong.");
    }
    return { status: false, message: error.message };
  }
};

export const noExponents = (amount) => {
  var data = String(amount).split(/[eE]/);
  if (data.length === 1) return data[0];

  var z = "",
    sign = amount < 0 ? "-" : "",
    str = data[0].replace(".", ""),
    mag = Number(data[1]) + 1;

  if (mag < 0) {
    z = sign + "0.";
    while (mag++) z += "0";
    return z + str.replace(/^\-/, "");
  }
  mag -= str.length;
  while (mag--) z += "0";
  return str + z;
};
