// import { Button } from 'bootstrap'
import React from "react";
import { Col, Container, Row, Button } from "react-bootstrap";
import "./PartnerPage.css";

function PartnerContent() {
  return (
    <div className="partner-detail text-center">
      <Container fluid className="custom-container">
        <Row>
          <Col className="mx-auto" lg={10}>
            <p>
              The only trusted exchange where users exchange shitcoins for
              rewards while allowing real companies and services to grow their
              customer base and communities.
            </p>
            <h1 className="partner-detail-header">Become a Partner</h1>
            <p>
              Schedule a call with us. We want to get to know you, answer any
              questions and verify that each service or project we offer to our
              users are legit. Here, we also discuss the technicals of getting
              your service listed. Listing is free of charge.
            </p>
            <Button className="talk-button" variant="none">
              <a
                href="https://calendly.com/cmascx/30min?month=2023-02&date=2023-02-03"
                className="text-white text-decoration-none"
                target="_blank"
              >
                Talk To Us
              </a>
            </Button>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default PartnerContent;
