import React, { useEffect } from "react";
import HeroSection from "./HeroSeection/HeroSection";

const LandingPage = () => {
  useEffect(() => {
    window.scroll(0, 0);
  }, []);
  return (
    <div className="landing-page">
      <HeroSection />
 
    </div>
  );
};

export default LandingPage;
