import React, { useEffect, useState } from "react";
import { Image } from "react-bootstrap";
import ShitCoin from "../../assets/images/shitcoinx.png";
import { toast } from "react-toastify";

import "./DODAdmin.css";

import {
  setCouponDOD,
  getCouponDOD,
  updateCouponDOD,
  removeCouponDOD,
} from "../../utils/axios";

function DODAdmin() {
  const [passkey, setPasskey] = useState("");
  const [pendingLoader, setPendingLoader] = useState(false);
  const [isPassKey, setIsPasskey] = useState(false);
  const [isDOD, setIsDOD] = useState(false);
  const [offerid, setOfferid] = useState("");
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");

  const getCoupon = async () => {
    setPendingLoader(true);
    setIsDOD(false);
    try {
      let couponData = await getCouponDOD(offerid);
      if (couponData.status) {
        if (couponData.data != null) {
          setDescription(couponData.data?.description);
          setTitle(couponData.data?.title);
          if (couponData.data?.categories == "Special Deals") setIsDOD(true);
          setIsPasskey(true);
        } else {
          toast.error("Coupon ID doesn't exist");
        }
        console.log(couponData);
        setPendingLoader(false);
      } else {
        toast.error(couponData.message);
        setPendingLoader(false);
      }
    } catch (e) {
      toast.error("Network error.");
      setPendingLoader(false);
    }
  };

  const setCoupon = async () => {
    setPendingLoader(true);
    try {
      let couponData = await updateCouponDOD(
        offerid,
        passkey,
        title,
        description
      );
      if (couponData.status) {
        toast.success(couponData.message);
        await getCoupon();
      } else {
        toast.error(couponData.message);
        setPendingLoader(false);
      }
    } catch (e) {
      toast.error("Network error.");
      setPendingLoader(false);
    }
  };

  const removeCoupon = async () => {
    setPendingLoader(true);
    try {
      let couponData = await removeCouponDOD(offerid, passkey);
      if (couponData.status) {
        toast.success(couponData.message);
        await getCoupon();
      } else {
        toast.error(couponData.message);
        setPendingLoader(false);
      }
    } catch (e) {
      toast.error("Network error.");
      setPendingLoader(false);
    }
  };

  useEffect(() => {
    window.scroll(0, 0);
  }, []);
  return (
    <>
      <section className="dodadmin-banner pb-5">
        <div className="dodadmin-banner-content text-center">
          <div className="dodadmin-banner-logo px-2">
            <h1 className="text-white mb-5">Deal of the Day Control</h1>

            {isPassKey ? (
              <>
                <div className="dodadmin-formtypes mt-2">
                  <h3 className="dodadmin-formtypes-heading text-white mb-4">
                    Passkey
                  </h3>
                  <form className="position-relative select-token-form w-100">
                    <input
                      className="dodadmin-formtypes-input h-100 border-0"
                      type={"text"}
                      placeholder="Enter Passkey"
                      value={passkey}
                      min={0}
                      onChange={(e) => {
                        setPasskey(e.target.value);
                      }}
                    />
                  </form>
                </div>
                <div className="dodadmin-formtypes mt-4">
                  <h3 className="dodadmin-formtypes-heading text-white mb-4">
                    Offer ID
                  </h3>
                  <form className="position-relative select-token-form w-100">
                    <input
                      className="dodadmin-formtypes-input h-100 border-0"
                      disabled
                      type={"text"}
                      placeholder="Enter Offer ID"
                      value={offerid}
                      min={0}
                      onChange={(e) => {
                        setOfferid(e.target.value);
                      }}
                    />
                  </form>
                </div>
                <div className="dodadmin-formtypes mt-4">
                  <h3 className="dodadmin-formtypes-heading text-white mb-4">
                    Title
                  </h3>
                  <form className="position-relative select-token-form w-100">
                    <input
                      className="dodadmin-formtypes-input h-100 border-0"
                      type={"text"}
                      placeholder="Enter Title"
                      value={title}
                      min={0}
                      onChange={(e) => {
                        setTitle(e.target.value);
                      }}
                    />
                  </form>
                </div>
                <div className="dodadmin-formtypes mt-4">
                  <h3 className="dodadmin-formtypes-heading text-white mb-4">
                    Description
                  </h3>
                  <form className="position-relative select-token-form w-100">
                    <textarea
                      rows="6"
                      className="dodadmin-formtypes-box h-100 border-0"
                      type={"text"}
                      placeholder="Enter Description"
                      value={description}
                      min={0}
                      onChange={(e) => {
                        setDescription(e.target.value);
                      }}
                    />
                  </form>
                </div>
                {pendingLoader ? (
                  <button
                    className="dodadmin-submit-button m-0 mt-4 mb-4 active"
                    id="set-coupon-button-loading"
                  >
                    <div
                      className="spinner-grow d-flex me-auto ms-auto text-white"
                      role="status"
                    >
                      <span className="sr-only d-flex justify-content-center ps-5 text-white">
                        {" "}
                      </span>
                    </div>
                  </button>
                ) : (
                  <button
                    className="dodadmin-submit-button m-0 mt-4 mb-4 active"
                    id="set-coupon-button"
                    onClick={setCoupon}
                  >
                    Set Deal of the Day
                  </button>
                )}
                {isDOD && <>
                    {pendingLoader ? (
                  <button
                    className="dodadmin-submit-button remove-dod-button m-0 mt-4 mb-4 active"
                    id="remove-coupon-button-loading"
                  >
                    <div
                      className="spinner-grow d-flex me-auto ms-auto text-white"
                      role="status"
                    >
                      <span className="sr-only d-flex justify-content-center ps-5 text-white">
                        {" "}
                      </span>
                    </div>
                  </button>
                ) : (
                  <button
                    className="dodadmin-submit-button remove-dod-button m-0 mt-4 mb-4 active"
                    id="remove-coupon-button"
                    onClick={removeCoupon}
                  >
                    Remove Deal of the Day
                  </button>
                )}
                </>}
              </>
            ) : (
              <>
                <div className="dodadmin-formtypes mt-4">
                  <h3 className="dodadmin-formtypes-heading text-white mb-4">
                    Offer ID
                  </h3>
                  <form className="position-relative select-token-form w-100">
                    <input
                      className="dodadmin-formtypes-input h-100 border-0"
                      type={"text"}
                      placeholder="Enter Offer ID"
                      value={offerid}
                      min={0}
                      onChange={(e) => {
                        setOfferid(e.target.value);
                      }}
                    />
                  </form>
                </div>
                {pendingLoader ? (
                  <button
                    className="dodadmin-submit-button m-0 mt-4 mb-4 active"
                    id="get-coupon-button-loading"
                  >
                    <div
                      className="spinner-grow d-flex me-auto ms-auto text-white"
                      role="status"
                    >
                      <span className="sr-only d-flex justify-content-center ps-5 text-white">
                        {" "}
                      </span>
                    </div>
                  </button>
                ) : (
                  <button
                    className="dodadmin-submit-button m-0 mt-4 mb-4 active"
                    id="get-coupon-button"
                    onClick={getCoupon}
                  >
                    Get Coupon
                  </button>
                )}
              </>
            )}
          </div>
        </div>
      </section>
    </>
  );
}

export default DODAdmin;
