import React, { useState } from "react";
import { Col, Container, Row, Image } from "react-bootstrap";
import { Link } from "react-router-dom";
import Linkedin from "../../../assets/images/linkedin.svg";
import Twitter from "../../../assets/images/twitter.svg";
import Discord from "../../../assets/images/discord.svg";
import Telegram from "../../../assets/images/telegram.svg";
import Youtube from "../../../assets/images/youtube.svg";
import Tiktok from "../../../assets/images/tiktok.svg";
import ShitCoin from "../../../assets/images/shitcoinx.png";
import "./Footer.css";
function Footer() {
  const [year, setYear] = useState(new Date().getFullYear());

  return (
    <footer className="footer">
      <Container fluid className="custom-container">
        <Row className="p-0 mt-0 mt-sm-3">
          <Col lg={7} className="d-lg-block d-none">
            <Row className="p-0 mt-4">
              <div className="coin-market-logo text-center">
                <Link to="/">
                  <Image src={ShitCoin} fluid />
                </Link>
              </div>
            </Row>
            <Row className="p-0 mt-4">
              <div className="coin-market-logo text-white">
                <p className="company-name">© {year} ShitCoinX</p>
              </div>
            </Row>
          </Col>

          <Col lg={5}>
            <Row className="p-0">
              <Col lg={6}>
                <ul className="nav-links text-start list-unstyled">
                  <li>
                    <Link to="/terms-conditions">Terms And Conditions</Link>
                  </li>

                  <li>
                    <Link to="/privacy-policy"> Privacy Policy</Link>
                  </li>
                  <li target="_blank">
                    <a href="https://blog.shitcoinx.com/" target="_blank">
                      {" "}
                      Blog
                    </a>
                  </li>
                  {/*
                  <li>
                    <a
                      href="https://blog.shitcoinx.com/partners"
                      target="_blank"
                    >
                      {" "}
                      Reward Partners
                    </a>
                  </li>
                */}
                  <li>
                    <a
                      href="https://blog.shitcoinx.com/coupon-submission-guidelines-and-terms/"
                      target="_blank"
                    >
                      {" "}
                      Coupon Submission
                    </a>
                  </li>
                </ul>
                <div className="mt-0 mt-md-4 mb-2 mb-md-4 mx-sm-auto mx-md-auto mx-lg-0">
                  <div className="wallet-btn wallet-btn-link mb-0 p-0 mx-sm-auto mx-md-auto mx-lg-0">
                    <a
                      href="https://www.producthunt.com/posts/shitcoin-xchange?utm_source=badge-featured&utm_medium=badge&utm_souce=badge-shitcoin&#0045;xchange"
                      target="_blank"
                      className="banner-text-link mb-0 mx-auto m-0 p-0 text-center text-decoration-none"
                      id="cta-product-hunt"
                    >
                      <img
                        src="https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=443781&theme=light"
                        alt={
                          "Shitcoin&#0032;Xchange - Swap&#0032;Crypto&#0032;&#0038;&#0032;NFTs&#0032;for&#0032;Shopping&#0032;coupons&#0032;on&#0032;10&#0044;000&#0043;&#0032;Stores | Product Hunt"
                        }
                        className="product-hunt-img"
                      />
                    </a>
                  </div>
                </div>
              </Col>
              <Col lg={6} className="mt-3 mt-lg-0">
                <ul className="nav-links text-start list-unstyled">
                  <li>
                    <a
                      href="mailto: hello@shitcoinx.com"
                      className="text-white text-decoration-none"
                    >
                      Contact: hello@shitcoinx.com{" "}
                    </a>
                  </li>
                </ul>
                <ul className="social-icons mb-0 d-flex ps-0">
                  {/*  <li className="social-icons-container">
                    <a
                      href="https://www.linkedin.com/company/coinmarketalerts"
                      target="_blank"
                    >
                      <Image
                        src={Linkedin}
                        fluid
                        className="social-media-icons"
                      />
                    </a>
                  </li>
                  */}
                  <li className="social-icons-container">
                    <a
                      href="https://twitter.com/ShitCoinXchange"
                      target="_blank"
                    >
                      <Image
                        src={Twitter}
                        fluid
                        className="social-media-icons"
                      />
                    </a>
                  </li>
                  <li className="social-icons-container">
                    <a href="https://t.me/shitcoinsexchange" target="_blank">
                      <Image
                        src={Telegram}
                        fluid
                        className="social-media-icons"
                      />
                    </a>
                  </li>
                  <li className="social-icons-container">
                    <a href="https://discord.gg/8UwgEUzVKR" target="_blank">
                      <Image
                        src={Discord}
                        fluid
                        className="social-media-icons"
                      />
                    </a>
                  </li>
                  <li className="social-icons-container">
                    <a href="https://www.youtube.com/@shitcoinx" target="_blank">
                      <Image
                        src={Youtube}
                        fluid
                        className="social-media-icons"
                      />
                    </a>
                  </li>
                  <li className="social-icons-container">
                    <a href="https://www.tiktok.com/@shitcoinx?_t=8n3dND2mpw9&_r=1" target="_blank">
                      <Image
                        src={Tiktok}
                        fluid
                        className="social-media-icons"
                      />
                    </a>
                  </li>
                </ul>
              </Col>
            </Row>
          </Col>

          <Col lg={7} className="d-flex d-lg-none footer-logo-section mt-4">
            <div className="coin-market-logo m-0 p-0">
              <Link to="/">
                <Image src={ShitCoin} fluid />
              </Link>
            </div>
            <div className="text-white footer-logo-section-text m-0 p-0 d-flex align-items-end">
              <h6 className="company-name ms-3">© {year} ShitCoinX</h6>
            </div>
          </Col>
        </Row>
      </Container>
    </footer>
  );
}

export default Footer;
