import React, { useCallback, useEffect, useState } from "react";
import { Container, Row, Col, Image, Button, Modal } from "react-bootstrap";
import ShitCoinGC from "../../../assets/images/shitcoin-gc2.png";
import CrossIcon from "../../../assets/images/cross-icon.svg";
import metamaskIcon from "../../../assets/images/metamask.png";
import plenaIcon from "../../../assets/images/plena.png";
import { SolanaWalletMultiButton } from "../../../component/solanacomponent/SolanaMultiButton";
import "../HeroSeection/HeroSection.css";
import "./ChoseWallet.css";
import "../../../component/solanacomponent/SolanaButton.css";
import ArrowBack from "../../../assets/images/arrow-back.svg";
import { Link, useNavigate } from "react-router-dom";
import { WalletModalProvider } from "@solana/wallet-adapter-react-ui";
import {
  useWalletConnectButton,
  useWalletDisconnectButton,
} from "@solana/wallet-adapter-base-ui";
import { useWeb3Connect } from "../../../contexts/Web3Connect";

import bs58 from "bs58";
import { useWallet } from "@solana/wallet-adapter-react";

import { usePlenaWallet } from "plena-connect-dapp-sdk";

export const ChoseWallet = () => {
  const {
    buttonState: solanaButtonState,
    walletName: solanaWalletName,
    walletIcon: solanaWalletIcon,
  } = useWalletConnectButton();

  const {
    buttonState: solanaDisconnectState,
    onButtonClick: solanaDisconnectButton,
  } = useWalletDisconnectButton();

  const {
    account: metamaskAccount,
    disconnectWallet: metamaskDisconnectButton,
  } = useWeb3Connect();

  const {
    openModal: plenaOpenModal,
    closeConnection: plenaCloseConnection,
    sendTransaction: plenaSendTransaction,
    walletAddress: plenaWalletAddress,
  } = usePlenaWallet();

  const { publicKey: solanaPublicKey } = useWallet();

  const [isMetamaskConnected, setIsMetamaskConnected] = useState(false);
  const [isSolanaConnected, setIsSolanaConnected] = useState(false);
  const [isPlenaConnected, setIsPlenaConnected] = useState(false);
  const [isSolanaConnectClicked, setIsSolanaConnectedClicked] = useState(false);
  const [isPlenaConnectClicked, setIsPlenaConnectedClicked] = useState(false);
  const [metamaskAccountDisplay, setMetamaskAccounDisplay] = useState("");
  const [solanaAccountDisplay, setSolanaAccounDisplay] = useState("");
  const [plenaAccountDisplay, setPlenaAccounDisplay] = useState("");


  const navigate = useNavigate();

  const connectPlena = useCallback(async () => {
    plenaOpenModal();
    setIsPlenaConnectedClicked(true);
  }, [plenaWalletAddress]);


  const disconnectWallet = useCallback(async () => {
    if(solanaButtonState != "no-wallet") solanaDisconnectButton();
    metamaskDisconnectButton();
    if(!(plenaWalletAddress == null ||  plenaWalletAddress == "")) plenaCloseConnection();
  }, [solanaButtonState]);

  const checkMetamaskConnection = useCallback(async () => {
    if (metamaskAccount.length > 0) {
      setMetamaskAccounDisplay(shortenHexString(metamaskAccount));
      setIsMetamaskConnected(true);
    } else {
      setIsMetamaskConnected(false);
    }
  }, [metamaskAccount]);

  const checkSolanaConnection = useCallback(async () => {
    if (solanaButtonState == "connected") {
      setSolanaAccounDisplay(shortenHexString(solanaPublicKey.toBase58()));
      setIsSolanaConnected(true);
    } else {
      setIsSolanaConnected(false);
    }
  }, [solanaButtonState, solanaDisconnectState]);

  const checkPlenaConnection = useCallback(async () => {
    if (plenaWalletAddress == null ||  plenaWalletAddress == "") {
      setIsPlenaConnected(false);
    } else {
      setPlenaAccounDisplay(shortenHexString(plenaWalletAddress));
      setIsPlenaConnected(true);
    }
  }, [plenaWalletAddress]);

  const handleSolanaButtonClick = useCallback(() => {
    if (solanaButtonState == "connected") {
        navigate("/solana/rewards");
    }
  }, [solanaButtonState]);

  const handlePlenaButtonClick = useCallback(() => {
    if (!(plenaWalletAddress == null ||  plenaWalletAddress == "")) {
        navigate("/plena/chain");
    }
  }, [plenaWalletAddress]);

  function shortenHexString(hexString, length = 4) {
    if (hexString?.length <= length * 2) {
      return hexString;
    }

    const prefix = hexString.slice(0, length);
    const suffix = hexString.slice(-length);

    return prefix + ".." + suffix;
  }

  useEffect(() => {
    checkMetamaskConnection();
    checkSolanaConnection();
    checkPlenaConnection();
    if (isSolanaConnectClicked) {
      handleSolanaButtonClick();
    }
    if(isPlenaConnectClicked) handlePlenaButtonClick();
  }, [solanaButtonState, solanaDisconnectState, metamaskAccount, plenaWalletAddress]);

  return (
    <>
      <button
        className="back-btn bg-transparent border-0 d-flex align-items-center"
        onClick={() => {
          disconnectWallet();
        }}
      >
        <Image className="me-1" src={ArrowBack} fluid />
        Logout
      </button>
      <div className="banner wallet-banner">
        <Container fluid className="custom-container">
          <Row className="align-items-center justify-content-between">
            <Col lg={5}>
              <div className="shit-coin-logo">
                <Image src={ShitCoinGC} fluid />
              </div>
            </Col>
            <Col lg={6}>
              <div className="content-wrapper pb-0 pb-lg-5">
                <div className="choose-heading text-center mx-auto mb-4 mb-md-5">
                  CHOOSE YOUR WALLET
                </div>
                <ul className="wallet-list align-items-center mx-auto mb-0">
                <li className="p-0">
                    {isPlenaConnected ? (
                      <Link
                        to="/plena/chain"
                        className="text-white text-center text-decoration-none p-3 walletlinks w-100"
                        style={{ display: "inline-block" }}
                      >
                        <Image
                          src={plenaIcon}
                          className="metamaskicon me-2"
                          fluid
                        />
                        {plenaAccountDisplay}
                      </Link>
                    ) : (
                      <Link
                        onClick={connectPlena}
                        className="text-white text-center text-decoration-none p-3 walletlinks w-100"
                        style={{ display: "inline-block" }}
                      >
                        Plena Wallet
                      </Link>
                    )}
                  </li>
                  <li className="p-0 mt-3">
                    {isMetamaskConnected ? (
                      <Link
                        to="/chain/metamask"
                        className="text-white text-center text-decoration-none p-3 walletlinks w-100"
                        style={{ display: "inline-block" }}
                      >
                        <Image
                          src={metamaskIcon}
                          className="metamaskicon me-2"
                          fluid
                        />
                        {metamaskAccountDisplay}
                      </Link>
                    ) : (
                      <Link
                        to="/chain/metamask"
                        className="text-white text-center text-decoration-none p-3 walletlinks w-100"
                        style={{ display: "inline-block" }}
                      >
                        MetaMask
                      </Link>
                    )}
                  </li>
                  <div className="p-0 mt-3 mb-0 mb-lg-5">
                    {isSolanaConnected ? (
                      <li className="p-0">
                        <Link
                          to="/solana/rewards"
                          className="text-white text-center text-decoration-none p-3 walletlinks w-100"
                          style={{ display: "inline-block" }}
                        >
                          <Image
                            src={solanaWalletIcon}
                            className="metamaskicon me-2"
                            fluid
                          />
                          {solanaAccountDisplay}
                        </Link>
                      </li>
                    ) : (
                      <WalletModalProvider>
                        <div onClick={() => setIsSolanaConnectedClicked(true)}>
                          <SolanaWalletMultiButton />
                        </div>
                      </WalletModalProvider>
                    )}
                  </div>
                  {/* <li className="p-0">
                        <Link
                          to="/chain/coinbase"
                          className="text-white text-start text-decoration-none p-3 walletlinks"
                          style={{ display: "inline-block" }}
                        >
                          Coinbase Wallet
                        </Link>
                      </li>
                      <li className="p-0">
                        <Link
                          to="/chain/walletconnect"
                          className="text-white text-start text-decoration-none p-3 walletlinks"
                          style={{ display: "inline-block" }}
                        >
                          Wallet Connect
                        </Link>
                      </li>
                      */}
                </ul>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};
