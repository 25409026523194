import React, { useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import "./privacyPolicy.css";
function PrivacyPolicy() {
  useEffect(() => {
    window.scroll(0, 0);
  }, []);
  return (
    <div className="policy-detail text-center">
      <Container fluid className="custom-container">
        <Row>
          <Col className="mx-auto mb-3" lg={10}>
            <h1>Privacy Policy</h1>
            <h5 className="mb-5">Last Updated: February 10, 2023</h5>
            <div className="policy-text-container">
              <h3 className="text-start">Introduction </h3>
              <p style={{ textAlign: "justify" }}>
                At ShitcoinX, we take the privacy and security of our users'
                information very seriously. This Privacy Policy outlines the
                information we collect, how we use it, and the steps we take to
                protect it
              </p>
              <p style={{ textAlign: "justify" }}>
                This policy applies to the service we provide for exchanging
                worthless crypto tokens in exchange for discount coupons for
                online shopping sites. By using our service, you agree to the
                collection, use, and sharing of your information as described in
                this Privacy Policy.
              </p>
            </div>
            <div className="policy-text-container">
              <h3 className="text-start">Information Collection and Use</h3>
              <p style={{ textAlign: "justify" }}>
                We do not collect personal identifying information from our
                users. We only collect information that is necessary for the
                operation and improvement of our service, such as the following:
              </p>
              <p style={{ textAlign: "justify" }}>
                Device Information: We collect information about the device you
                use to access our service, such as the type of device, operating
                system, and unique device identifier
              </p>
              <p style={{ textAlign: "justify" }}>
                Log Data: Our service utilizes Google Analytics to collect the
                IP addresses of users who visit the website. Google Analytics
                uses the IP address to determine the general geographic location
                of the user and to provide website owners with demographic
                information about their visitors.
              </p>
              <p style={{ textAlign: "justify" }}>
                Crypto Decentralized Wallet Address: We collect the user's
                crypto decentralized wallet address for record-keeping purposes
                related to the transactions made through our service.
              </p>
              <h4 className="text-start">We use the information we collect to:</h4>
              <ul className="text-start">
                <li>Provide and improve our services.</li>
                <li>Process transactions and provide customer support.</li>
                <li>Communicate with you about our services and respond to your
                  inquiries.</li>
                <li>Prevent fraudulent or illegal activities.</li>
                <li>Information Sharing and Disclosure.</li>
              </ul>
              <p style={{ textAlign: "justify" }}>
                We do not sell, rent, or share your information with third parties
                for their marketing purposes without your explicit consent.
                However, we may share your wallet information in the following circumstances:
              </p>
              <p style={{ textAlign: "justify" }}>
                With third-party service providers who perform services on our behalf,
                such as processing transactions, providing customer support, and conducting analytics.
              </p>
              <p style={{ textAlign: "justify" }}>
                To comply with legal obligations, such as responding to subpoenas,
                court orders, or other legal processes.
              </p>
              <p style={{ textAlign: "justify" }}>
                To enforce our policies and protect our rights.
              </p>
            </div>
            <div className="policy-text-container">
              <h3 className="text-start">Data Security</h3>
              <p style={{ textAlign: "justify" }}>
                We take steps to secure your personal information and prevent
                unauthorized access to it. We use encryption and other security
                measures to protect your information in transit and at rest.
                However, no method of transmission over the Internet or method
                of electronic storage is 100% secure, and we cannot guarantee
                the absolute security of your information.
              </p>
            </div>
            <div className="policy-text-container">
              <h3 className="text-start">International Transfer of Information</h3>
              <p style={{ textAlign: "justify" }}>
                The information we collect may be stored, processed, and
                accessed in countries outside of your home country. The privacy
                laws in these countries may be different from those in your home
                country and may not provide an equivalent level of protection.
                By using our service, you consent to the transfer of your
                information to other countries.
              </p>
            </div>
            <div className="policy-text-container">
              <h3 className="text-start">Your Choices and Access to Your Information</h3>
              <p style={{ textAlign: "justify" }}>
                You have the right to request access to your personal
                information and to request that we correct or delete it. You can
                also opt out of receiving promotional communications from us.
              </p>
              <p style={{ textAlign: "justify" }}>
                If you would like to exercise these rights, please contact us at
                <span> hello@shitcoinx.com</span>. We will respond to your
                request as soon as possible.
              </p>
            </div>
            <div className="policy-text-container">
              <h3 className="text-start">Changes to Our Privacy Policy</h3>
              <p style={{ textAlign: "justify" }}>
                We may update this Privacy Policy from time to time to reflect
                changes in our practices or applicable laws. We will post any
                changes on this page and indicate the date of the latest
                revision. Your continued use of our service after any changes
                constitutes your acceptance of the new Privacy Policy.
              </p>
              <p style={{ textAlign: "justify" }}>
                If you would like to exercise these rights, please contact us at
                <span> hello@shitcoinx.com</span>. We will respond to your
                request as soon as possible.
              </p>
            </div>
            <div className="policy-text-container">
              <h3 className="text-start">Contact Us</h3>
              <p style={{ textAlign: "justify" }}>
                If you have any questions or concerns about this Privacy Policy,
                please contact us at <span>hello@shitcoinx.com</span>
              </p>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default PrivacyPolicy;
