import { injected, CoinbaseWallet, walletconnect } from "../utils/connectors";

export function activateInjectedProvider(providerName) {
    const { ethereum } = window;

    if (!ethereum?.providers) {
        return undefined;
    }

    let provider;
    switch (providerName) {
        case 'CoinBase':
            provider = ethereum.providers.find(({ isCoinbaseWallet }) => isCoinbaseWallet);
            break;
        case 'MetaMask':
            provider = ethereum.providers.find(({ isMetaMask }) => isMetaMask);
            break;
    }

    if (provider) {
        ethereum.setSelectedProvider(provider);
    }
}


export async function returnInjectedProvider(providerName) {
    const { ethereum } = window;

    if (!ethereum?.providers) {
        return undefined;
    }

    let provider;
    switch (providerName) {
        case 'CoinBase':
            provider = ethereum.providers.find(({ isCoinbaseWallet }) => isCoinbaseWallet);
            break;
        case 'MetaMask':
            provider = ethereum.providers.find(({ isMetaMask }) => isMetaMask);
            break;
        case 'WalletConnect':
            let chainId = 1;
            await walletconnect.connect({chainId});  
            provider = await walletconnect.getProvider({chainId});
            break;
    }

    return provider
}