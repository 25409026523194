import React, { useEffect, useState } from "react";
import "./freetxpool.css";
import { getFreeTXPool } from "../../utils/axios";
import Stack from "@mui/joy/Stack";
import { FaInfoCircle } from "react-icons/fa";
import LinearProgress from "@mui/joy/LinearProgress";
import Typography from "@mui/joy/Typography";

function FreeTxPool() {
  const [percentageValue, setPercentageValue] = useState(0);
  const [amount, setAmount] = useState(1);
  const [expectedAmount, setExpectedAmount] = useState(1000);
  const [message, setMessage] = useState("");

  const calculatePercentage = (amount, expectedAmount) => {
    if (expectedAmount !== 0) {
      const calculatedPercentage = (amount / expectedAmount) * 100;
      setPercentageValue(calculatedPercentage);
    } else {
      setPercentageValue(0);
    }
  };

  const getPool = async () => {
    let data = await getFreeTXPool("genesis");
    if (data.list != null) {
      
      if(data.list?.amount > data.list?.expectedamount){
        setAmount(15);
        setExpectedAmount(data.list?.expectedamount);
        setMessage(`Goal Completed`);
      }else{
        setAmount(data.list?.amount);
        setExpectedAmount(data.list?.expectedamount);
        setMessage(`Current Progress: ${data.list?.amount}/ ${data.list?.expectedamount} FreeTX Coupons`)
      }

    }

    console.log(data);
  };

  useEffect(() => {
    getPool();
    calculatePercentage(amount, expectedAmount);
  }, [amount, expectedAmount]);

  return (
    <>
      <div className="freetxpoolcontainer mb-5">
        <div className="d-flex flex-row-reverse mb-3">
          <div className="freetxpoolcontainer-live p-1 px-2">Epoch 1 LIVE</div>
        </div>
        <div className="freetxpoolcontainer-title text-center m-2">
          Unlock the Redemption Pool by claiming FreeTX Coupons!
        </div>

        <LinearProgress
          determinate
          value={percentageValue}
          thickness={30}
          sx={{
            "--LinearProgress-radius": "5px",
            "--LinearProgress-progressThickness": "29px",
            boxShadow: "sm",
            borderColor: "neutral.500",
          }}
        >
          <Typography
            level="body-xs"
            fontWeight="xl"
            textColor="common.white"
            sx={{ mixBlendMode: "difference" }}
          >
            {message}
          </Typography>
        </LinearProgress>
        {/*<div className="text-primary mt-3">
          <FaInfoCircle /> Click here to read more about the Redemption Pool
        </div>*/}
      </div>
    </>
  );
}

export default FreeTxPool;
