import React, { useEffect } from "react";
import { Container, Row, Col, Image } from "react-bootstrap";
import ShitCoin from "../../assets/images/shitcoin-gc.png";
import { useNavigate, useParams } from "react-router-dom";
import { useWeb3Connect } from "../../contexts/Web3Connect";

import "./ShitcoinChain.css";

function Banner() {
  const navigate = useNavigate();
  const params = useParams();
  const { account, connectWallet } = useWeb3Connect();

  useEffect(() => {
    if (account.length > 0) {
      navigate("/rewards");
    }
  }, [account]);
  useEffect(() => {
    window.scroll(0, 0);
  }, []);
  return (
    <div className="banner wallet-banner">
      <Container fluid className="custom-container">
        <Row className="align-items-center">
          <Col lg={5} className="">
            <div className="shit-coin-logo">
              <Image src={ShitCoin} fluid />
            </div>
          </Col>
          <Col lg={1}></Col>

          <Col lg={6} className="shitcoin-chain-blockchain-choice">
            <div className="content-wrapper pb-0 pb-lg-5">
              <h1 className="shitcoin-chain-blockchain-choice-header mt-0 text-center">CHOOSE BLOCKCHAIN</h1>
              <ul className="wallet-list m-0 mt-3 mt-md-4 mb-0 mb-lg-5 p-0 mx-auto">
                <li className="m-0 p-0 mb-3">
                  <button
                    className="chain-button p-3 ps-4 text-center"
                    onClick={() => {
                      connectWallet(1, params.wallet);
                    }}
                  >
                    Ethereum
                  </button>
                  {/* </button> */}
                </li>
                <li className="m-0 p-0 mb-3">
                  <button
                    className="chain-button p-3 ps-4 text-center"
                    onClick={() => {
                      connectWallet(56, params.wallet);
                    }}
                  >
                    Binance SmartChain
                  </button>
                </li>
                <li className="m-0 p-0 mb-0 mb-lg-5">
                  <button
                    className="chain-button p-3 ps-4 text-center"
                    onClick={() => {
                      connectWallet(137, params.wallet);
                    }}
                  >
                    Polygon
                  </button>
                </li>
              </ul>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
export default Banner;
