import axios from "axios";

export const userWallet = async (wallet_address) => {
  try {
    const url = process.env.REACT_APP_SITE_URL + "wallets/";
    const { data } = await axios.post(url, { wallet_address });
    if (data.wallet) {
      localStorage.setItem("UserWallet", JSON.stringify(data.wallet));
      return { status: true, message: data.message, wallet: data.wallet };
    }
  } catch (error) {
    return { status: false, message: error.message };
  }
};

export const setProvider = ( _provider) =>{
  localStorage.setItem("Provider", _provider);
}

export const saveFavorite = (favorite) => {
  localStorage.setItem("favorites", JSON.stringify(favorite));
}

export const removeWallet = () =>{
  localStorage.removeItem("UserWallet");
  localStorage.removeItem("Provider");
}

export const saveUserTransaction = async (
  tokenType,
  number,
  value,
  address,
  txHash,
  network
) => {
  try {
    const payload = {
      type: 1,
      tokenType,
      number,
      value,
      address,
      txHash,
      network
    };
    const url = process.env.REACT_APP_SITE_URL + "transactions/";
    const { data } = await axios.post(url, payload);
    if (data.data) {
      return { status: true, transactions: data.data };
    }
  } catch (error) {
    return { status: false, message: error.message };
  }
};

export const couponCode = async (offerid) => {
  try {
    const url = process.env.REACT_APP_SITE_URL + `coupon/get`;
    const options = {
      method: "POST",
      url: url,
      headers: {
        "Content-Type": "application/json",
      },
      data: {
        offerid: offerid
      },
    };

    let { data } = await axios(options);
    return { status: true, coupon: data.coupon };
  } catch (error) {
    return { status: false, message: error.message };
  }
};

export const couponAvailability = async (offerid) => {
  try {
    const url = process.env.REACT_APP_SITE_URL + `coupon/availability`;
    const queryParams = {
      offerid: offerid
    };

    let { data } = await axios.get(url, {
      params: queryParams,
    });
    return { status: true, coupon: data.coupon };
  } catch (error) {
    return { status: false, message: error.message };
  }
};

export const getCouponImage = async () => {
  try {
    const url = process.env.REACT_APP_SITE_URL + `coupon/couponimage`;
    const { data } = await axios.get(url);
    return { status: true, rewardsList: data.rewardsList };
  } catch (error) {
    return { status: false, message: error.message };
  }
};

export const getQuestStatus = async (goldencoupons, tx) => {
  try {
    const payload = {
      goldencoupons: goldencoupons,
      tx: tx
    };
    const url = process.env.REACT_APP_SITE_URL + "coupon/list";
    const { data } = await axios.post(url, payload);
    return { status: true, couponstatus: data.couponstatus };
  } catch (error) {
    return { status: false, message: error.message };
  }
};


export const getQuestDailyStatus = async (address) => {
  try {
    const payload = {
      address: address
    };
    const url = process.env.REACT_APP_SITE_URL + "quest/dailytx";
    const { data } = await axios.post(url, payload);
    return { status: true, count: data.count };
  } catch (error) {
    return { status: false, message: error.message };
  }
};

export const getCouponsCollected = async (address) => {
  try {
    const payload = {
      address: address
    };
    const url = process.env.REACT_APP_SITE_URL + "quest/user";
    const { data } = await axios.post(url, payload);
    return { status: true, count: data.count };
  } catch (error) {
    return { status: false, message: error.message };
  }
};


export const getTXDailyStatus = async (address) => {
  try {
    const payload = {
      address: address
    };
    const url = process.env.REACT_APP_SITE_URL + "transactions/dailytx";
    const { data } = await axios.post(url, payload);
    return { status: true, count: data.count };
  } catch (error) {
    return { status: false, message: error.message };
  }
};

export const getLastTxStatus = async (address) => {
  try {
    const payload = {
      address: address
    };
    const url = process.env.REACT_APP_SITE_URL + "transactions/lasttx";
    const { data } = await axios.post(url, payload);
    return { status: true, count: data.count };
  } catch (error) {
    return { status: false, message: error.message };
  }
};

export const updateQuest = async (offerid, address, datecollected ) => {
  try {
    const payload = {
      offerid: offerid,
      address: address,
      datecollected: datecollected
    };
    const url = process.env.REACT_APP_SITE_URL + "quest/update";
    const { data } = await axios.post(url, payload);
    return { status: true, count: data.count };
  } catch (error) {
    return { status: false, message: error.message };
  }
};

export const burnQuest = async (offerid) => {
  try {
    const payload = {
      offerid: offerid
    };
    const url = process.env.REACT_APP_SITE_URL + "coupon/burn";
    const { data } = await axios.post(url, payload);
    return { status: true, count: data.count };
  } catch (error) {
    return { status: false, message: error.message };
  }
};

export const getFreeTXPool = async (poolid) => {
  try {
    const payload = {
      poolid: poolid
    };

    const url = process.env.REACT_APP_SITE_URL + "freetxpool/get";
    const { data } = await axios.post(url, payload);
    return { status: true, list: data.list };
  } catch (error) {
    return { status: false, message: error.message };
  }
};

export const getFreeTXStatus = async (address) => {
  try {
    const payload = {
      address: address.toLowerCase()
    };
    const url = process.env.REACT_APP_SITE_URL + "freetxlist/get";
    const { data } = await axios.post(url, payload);
    return { status: true, list: data.list };
  } catch (error) {
    return { status: false, message: error.message };
  }
};

export const addFreeTX = async (address, amount) => {
  try {
    const payload = {
      address: address.toLowerCase(),
      amount: amount
    };
    const url = process.env.REACT_APP_SITE_URL + "freetxlist/";
    const { data } = await axios.post(url, payload);
    return { status: true, list: data.list };
  } catch (error) {
    return { status: false, message: error.message };
  }
};

export const addFreeTXPool = async (poolid, amount) => {
  try {
    const payload = {
      poolid: poolid,
      amount: amount
    };
    const url = process.env.REACT_APP_SITE_URL + "freetxpool/";
    const { data } = await axios.post(url, payload);
    return { status: true, list: data.list };
  } catch (error) {
    return { status: false, message: error.message };
  }
};

export const claimFreeTX = async (address) => {
  try {
    const payload = {
      address: address.toLowerCase(),
      amount: 1
    };
    const url = process.env.REACT_APP_SITE_URL + "freetxlist/claim";
    const { data } = await axios.post(url, payload);
    return { status: true, list: data.list };
  } catch (error) {
    return { status: false, message: error.message };
  }
};

export const getCouponDOD = async (offerid) => {
  try {
    const payload = {
      offerid: offerid
    };
    const url = process.env.REACT_APP_SITE_URL + "coupon/get";
    const { data } = await axios.post(url, payload);
    return { status: data.status, data: data.coupon };
  } catch (error) {
    return { status: false, message: error.message };
  }
};

export const setCouponDOD = async (offerid, passkey) => {
  try {
    const payload = {
      offerid: offerid,
      passkey: passkey
    };
    const url = process.env.REACT_APP_SITE_URL + "coupon/setdod";
    const { data } = await axios.post(url, payload);
    return { status: data.status, message: data.message, data: data.coupon };
  } catch (error) {
    return { status: false, message: error.message };
  }
};

export const updateCouponDOD = async (offerid, passkey, title, description) => {
  try {
    const payload = {
      offerid: offerid,
      passkey: passkey,
      title: title,
      description: description
    };
    const url = process.env.REACT_APP_SITE_URL + "coupon/updatedod";
    const { data } = await axios.post(url, payload);
    return { status: data.status, message: data.message, data: data.coupon};
  } catch (error) {
    return { status: false, message: error.message };
  }
};


export const removeCouponDOD = async (offerid, passkey) => {
  try {
    const payload = {
      offerid: offerid,
      passkey: passkey
    };
    const url = process.env.REACT_APP_SITE_URL + "coupon/deletedod";
    const { data } = await axios.post(url, payload);
    return { status: data.status, message: data.message, data: data.coupon};
  } catch (error) {
    return { status: false, message: error.message };
  }
};



export const getCategory = async (category, goldencoupons, tx) => {
  try {
    const url = process.env.REACT_APP_SITE_URL + `coupon/reward-option`;
    const options = {
      method: "POST",
      url: url,
      headers: {
        "Content-Type": "application/json",
      },
      data: {
        category: category,
        goldencoupons: goldencoupons,
        tx: tx
      },
    };
    const { data } = await axios(options);
    return { status: true, rewardsList: data.rewardsList };
  } catch (error) {
    return { status: false, message: error.message };
  }
};

export const getPriceInUSD = async (chain, freeTX) => {
  const url =
    chain === 1
      ? process.env.REACT_APP_ETH_IN_USD
      : chain === 4 
      ? process.env.REACT_APP_SOL_IN_USD
      : chain === 56
      ? process.env.REACT_APP_BNB_IN_USD
      : process.env.REACT_APP_POLY_IN_USD;
  try {
    const price = await axios.get(url);
    let amount = 0;
    if(!freeTX){amount = 0.5;} 
    const payable = amount / price.data.USD;
    return { status: true, payable: payable };
  } catch (error) {
    return { status: false, message: error.message };
  }
};
export const saveUserActivities = async (title, item, tokenType, number, from, to) => {
  try {
    const payload = {
      title,
      item,
      tokenType,
      number,
      from,
      to,
    };
    const url = process.env.REACT_APP_SITE_URL + "activities/";
    const { data } = await axios.post(url, payload);
    if (data.transactions) {
      return { status: true, transactions: data.transactions };
    }
  } catch (error) {
    return { status: false, message: error.message };
  }
};
