import { InjectedConnector } from "@wagmi/core";
import { WalletConnectConnector } from "@wagmi/core/connectors/walletConnect";
import { mainnet, bsc, polygon } from "@wagmi/core/chains";
import { CoinbaseWalletConnector } from "@wagmi/core/connectors/coinbaseWallet";
import { publicProvider } from "@wagmi/core/providers/public";
import { configureChains, createConfig } from "wagmi";

// Instanciate your other connectors.
//export const injected = new InjectedConnector({supportedChainIds: [1, 56, 137]})

// Instanciate your other connectors.
export const injected = new InjectedConnector({
  chains: [mainnet, bsc, polygon],
});

/*export const walletconnect = new WalletConnectConnector({
  rpcUrl: `https://mainnet.infura.io/v3/${process.env.REACT_APP_INFURA_ID}`,
  bridge: "https://bridge.walletconnect.org",
  qrcode: true,
})*/

export const walletconnect = new WalletConnectConnector({
  //chains: [mainnet, bsc, polygon],
  options: {
    projectId: process.env.REACT_APP_WALLETCONNECT_ID,
    showQrModal: true,
  },
});

/*export const CoinbaseWallet = new WalletLinkConnector({
  url: `https://mainnet.infura.io/v3/${process.env.REACT_APP_INFURA_ID}`,
  infuraId: process.env.REACT_APP_INFURA_ID,
  appName: "Shitcoin X"
 }); */

export const CoinbaseWallet = new CoinbaseWalletConnector({
  chains: [mainnet, bsc, polygon],
  options: {
    appName: "Shitcoin X",
    jsonRpcUrl: `https://mainnet.infura.io/v3/${process.env.REACT_APP_INFURA_ID}`,
  },
});

export const { chains, publicClient, webSocketPublicClient } = configureChains(
  [mainnet, bsc, polygon],
  [publicProvider()]
);

export const wagmiconfig = createConfig({
  autoConnect: true,
  connectors: [injected, walletconnect, CoinbaseWallet],
  publicClient,
  webSocketPublicClient,
});

const connectors = {
  injected,
  walletconnect,
  CoinbaseWallet,
};

export default connectors;
