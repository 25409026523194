import { useEffect } from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import LandingPage from "./pages/LandingPage";
import Rewads from "./pages/shitcoin-chain/ShitcoinChain";
import "bootstrap/dist/css/bootstrap.min.css";
import Partner from "./pages/PartnerPage/PartnerPage";
import TermsCondtions from "./pages/terms&Conditons/termsCondtions";
import PrivacyPolicy from "./pages/PrivacyPolicy/privacyPolicy";
import RewardsDropdown from "./component/rewardsdropdown/rewardsdropdown";
import "./App.css";
import RewardsOptions from "./pages/Rewards/shitcoin-rewards";
import { ToastContainer } from "react-toastify";
import Footer from "./pages/LandingPage/Footer/Footer";
import { ChoseWallet } from "./pages/LandingPage/ChoseWallet/ChoseWallet";
import SolanaChain from "./pages/SolanaChain/SolanaChain";
import ChooseChain from "./pages/PlenaWallets/ChooseChain";
import PlenaRewards from "./pages/PlenaWallets/PlenaRewards";
import DODAdmin from "./pages/DODAdmin/DODAdmin";

const App = () => {
  useEffect(() => {
    window.scroll(0, 0);
  }, []);
  return (
    <div className="wrapper">
      <Routes>
        <Route path="/" exact element={<LandingPage />} />
        <Route path="/wallet" exact element={<ChoseWallet />} />
        <Route path="/chain/:wallet" exact element={<Rewads />} />
        <Route path="/rewards" exact element={<RewardsOptions />} />
        <Route path="/partner" exact element={<Partner />} />
        <Route path="/privacy-policy" exact element={<PrivacyPolicy />} />
        <Route path="/dropdown" exact element={<RewardsDropdown />} />
        <Route path="/terms-conditions" exact element={<TermsCondtions />} />
        <Route path="/solana/rewards" exact element={<SolanaChain />} />
        <Route path="/plena/chain" exact element={<ChooseChain />} />
        <Route path="/plena/rewards" exact element={<PlenaRewards />} />
        <Route path="/v1/control/dod" exact element={<DODAdmin />} />
        <Route path="*" element={<Navigate to="/" />} />{" "}
      </Routes>
      <Footer />
      <ToastContainer />
    </div>
  );
};

export default App;
