import { useState } from "react";
import { toast } from "react-toastify";

export default function usePersistentToast(message = "", type = "default") {
  const [persistentToastID, setPersistentToastID] = useState();

  /**
   * Dismisses the current toast and triggers a new one. If no toast is active, it will trigger a new one.
   */
  const trigger = () => {
    toast.dismiss(persistentToastID);
    const toastID = toast(message, {
      type,
      autoClose: true,
      closeButton: true,
    });
    setPersistentToastID(toastID);
  };

  /**
   * Dismisses the current toast.
   */
  const dismiss = () => {
    setPersistentToastID(undefined);
    toast.dismiss(persistentToastID);
  };

  return { trigger, dismiss };
}
