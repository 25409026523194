const categorydata = [
    {
        key: "Shitcoinx",
        name: "ShitcoinX FreeTx",
        image: "/category/shitcoin.svg"
    },
    {
        key: "Featured",
        name: "Featured Offers",
        image: "/category/featured.svg"
    },
    {
        key: "DOD",
        name: "Deal of the Day",
        image: "/category/deal.svg"
    },
    {
        key: "Electronics",
        name: "Electronics",
        image: "/category/electronics.svg"
    },
    {
        key: "Media",
        name: "Media",
        image: "/category/media.svg"
    },
    {
        key: "Cosmetics",
        name: "Cosmetics",
        image: "/category/cosmetics.svg"
    },
    {
        key: "Automobile",
        name: "Automobile",
        image: "/category/automobiles.svg"
    },
    {
        key: "Fashion",
        name: "Fashion",
        image: "/category/fashion.svg"
    },
    {
        key: "Gaming",
        name: "Gaming",
        image: "/category/gaming.svg"
    },
    {
        key: "Services",
        name: "Services",
        image: "/category/services.svg"
    },
    {
        key: "Women",
        name: "Women",
        image: "/category/woman.svg"
    },
    {
        key: "Sports",
        name: "Sports",
        image: "/category/sports.svg"
    },
    {
        key: "Pets",
        name: "Pets",
        image: "/category/pet.svg"
    },
    {
        key: "Kids",
        name: "Kids",
        image: "/category/kids.svg"
    },
    {
        key: "Health",
        name: "Health",
        image: "/category/health.svg"
    },
    {
        key: "Home",
        name: "Home/Office",
        image: "/category/home.svg"
    },
    {
        key: "Random",
        name: "Random",
        image: "/category/random.svg"
    }

]

export default categorydata;