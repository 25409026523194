import React, { useEffect, useState, useCallback } from "react";
import { Container, Row, Col, Image, Dropdown } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import ShitCoin from "../../assets/images/shitcoin-gc3.png";
import { FaInfoCircle, FaWallet, FaRedo } from "react-icons/fa";
import { MdFavorite, MdRemoveCircle, MdShield } from "react-icons/md";
import { HiTicket } from "react-icons/hi";
import ArrowBack from "../../assets/images/arrow-back.svg";
import Goldcard from "../../assets/images/gold.png";
import Metalcard from "../../assets/images/metal.png";
import CopyIcon from "../../assets/images/copy-icon.svg";
import { useMetamask } from "../../contexts/Metamask";
import { useWeb3Connect } from "../../contexts/Web3Connect";
import Twitter from "../../assets/images/twitter.svg";
import axios from "axios";
import web3 from "web3";
import Moralis from "moralis";
import { ethers, utils } from "ethers";
import "../shitcoin-chain/ShitcoinChain.css";
import { toast } from "react-toastify";
import {
  saveUserTransaction,
  couponCode,
  saveUserActivities,
  getQuestDailyStatus,
  getTXDailyStatus,
  getQuestStatus,
  getCategory,
  addFreeTX,
  addFreeTXPool,
  couponAvailability,
  getCouponsCollected,
  burnQuest,
  updateQuest,
  getFreeTXStatus,
  claimFreeTX,
} from "../../utils/axios";
import {
  TransferToken,
  TransferNFTERC721,
  TransferNFTERC1155,
} from "../../utils/contract";
import { Loader } from "../Loader/loader";
import { returnInjectedProvider } from "../../helpers/activateinjectedprovider";

import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import categorydata from "../../helpers/categorydata";
import FreeTxPool from "../../component/freetxpool/freetxpool";

let tokenInformation = {
  address: "",
  tokenName: "",
  tokenId: "",
  tokenSymbol: "",
  contractType: "",
  tokenAmount: 0,
  tokenDecimals: "",
};

function RewardsOptions() {
  //states
  const [selectedTokens, setSelectedToken] = useState("");
  const [filteredRewards, setFilteredRewards] = useState([]);
  let [approve, setApproved] = useState(false);
  let [amount, setAmount] = useState(0);
  let [wallet, setWallet] = useState("");
  const [fetchingToken, setFetchingToken] = useState(true);
  // const [abi, setABI] = useState([]);
  const [userWalletTokens, setUserWalletTokens] = useState([]);
  const [coupon, setCoupon] = useState({});
  const [selectedRewards, setSelectedRewards] = useState();
  const [tokenType, setTokenType] = useState("");
  const [userSelection, setUserSelection] = useState([]);
  const [contractType, setContractType] = useState("");
  const [loader, setLoader] = useState(false);
  const [loaderMessage, setLoaderMessage] = useState("");
  const [proxeyContract, setProxeyContract] = useState([]);
  const rAddress = process.env.REACT_APP_TOKEN_RECIVER_ADDRESS;

  //Reward Dropdown
  const [data, setData] = useState([]);
  const [expandedCategory, setExpandedCategory] = useState("");
  const [selectedItem, setSelectedItem] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("");
  const [showDropdown, setShowDropdown] = useState(false);
  const [showCategory, setShowCategory] = useState(false);
  const [favorites, setFavorites] = useState([]);
  const [fetchingRewards, setFetchingRewards] = useState(true);

  const [groupedData, setGroupedData] = useState([]);

  const categoryData = categorydata;

  //Golden Coupons
  const [gcMessage, setGCMessage] = useState("");
  const [gcQualified, setGCQualified] = useState(false);
  const [gcCollected, setGCCollected] = useState(0);
  const [dailyTX, setDailyTX] = useState(0);
  const [dailyQuest, setDailyQuest] = useState(0);
  const [gcWinner, setGCWinner] = useState(false);

  //Free TX
  const [freeTXQualified, setFreeTXQualified] = useState(false);
  const [freeTXAmount, setFreeTXAmount] = useState(0);
  const [freeTXState, setfreeTXState] = useState(false);
  const [isFreeTXCoupon, setIsFreeTXCoupon] = useState(false);

  //Disable functionality
  const [disableFunction, setDisableFunction] = useState(false);

  //navigate to routes
  const navigate = useNavigate();

  //web3utils
  const web3utils = require("web3-utils");

  //Today
  const todayDate = new Date();

  //metamask handling
  //const { account, disconnect, isConnectedToAllowedNetwork, chain } =useMetamask();

  //Web3 connect handling
  const { account, disconnectWallet, isConnectedToAllowedNetwork, chain } =
    useWeb3Connect();

  //getUser tokens
  let getUserTokens = async () => {
    try {
      setDisableFunction(true);
      setLoaderMessage("Fetching Tokens...");
      setLoader(true);
      setUserWalletTokens([]);
      setSelectedToken("");
      setAmount(0);
      let verfiedTokens = [];
      let tokens;
      let eth_provider = window.ethereum;

      let provider = localStorage.getItem("Provider");

      eth_provider = await returnInjectedProvider(provider);

      if (eth_provider === undefined) eth_provider = window.ethereum;

      let chainId = 1;

      if (provider == "WalletConnect") {
        chainId = 1;
      } else {
        chainId = parseInt(
          await eth_provider.request({ method: "eth_chainId" })
        );
      }
      if (!Moralis.Core.isStarted) {
        await Moralis.start({
          apiKey: process.env.REACT_APP_MORALIS_API_KEY,
          // ...and any other configuration
        });
      }

      if (tokenType === "NFT") {
        const address = eth_provider.selectedAddress;

        const chain = `0x${chainId.toString(16)}`;

        const data = await Moralis.EvmApi.nft.getWalletNFTs({
          chain: chain,
          excludeSpam: true,
          mediaItems: false,
          address: address,
        });
        tokens = data.result;
      } else {
        const address = eth_provider.selectedAddress;

        const chain = `0x${chainId.toString(16)}`;

        const data = await Moralis.EvmApi.token.getWalletTokenBalances({
          address,
          chain,
        });
        tokens = data.result;
      }
      if (tokens.length > 0) {
        for (let index = 0; index < tokens.length; index++) {
          const element = tokens[index];
          let isVerifed;
          if (tokenType == "NFT") {
            if (!element.possibleSpam) {
              isVerifed = await getVerifiedABI(element.tokenAddress?._value);
              if (element.contractType === "ERC1155") {
                tokenInformation.address = element.tokenAddress?._value;
                tokenInformation.tokenAmount = element.amount;
                tokenInformation.tokenName = element.name
                  ? element.name
                  : "ERC1155 TokenID " + element.tokenId;
                tokenInformation.tokenSymbol = element.symbol
                  ? element.symbol
                  : "TOKEN(S)";
                tokenInformation.contractType = element.contractType;
                tokenInformation.tokenId = element.tokenId;
                tokenInformation.tokenDecimals = "18";
              } else {
                tokenInformation.address = element.tokenAddress?._value;
                tokenInformation.tokenAmount = element.amount;
                tokenInformation.tokenId = element.tokenId;
                tokenInformation.tokenName = element.name;
                tokenInformation.tokenSymbol = element.symbol;
                tokenInformation.contractType = element.contractType;
                tokenInformation.tokenDecimals = "18";
              }
            }
          } else {
            if (!element._token._value.possibleSpam) {
              isVerifed = await getVerifiedABI(
                element._token._value.contractAddress._value
              );
              tokenInformation.address =
                element._token._value.contractAddress._value;
              tokenInformation.tokenAmount = ethers.utils.formatUnits(
                element._value.amount.value,
                element._value.decimals
              );
              tokenInformation.tokenName = element._token._value.name;
              tokenInformation.tokenSymbol = element._token._value.symbol;
              tokenInformation.contractType = "ERC20";
              tokenInformation.tokenId = "";
              tokenInformation.tokenDecimals = element._value.decimals;
            }
          }
          if (isVerifed) {
            verfiedTokens.push({
              ...tokenInformation,
              abi: isVerifed?.result[0].ABI,
            });
          }
        }
      }
      setFetchingToken(false);
      setUserWalletTokens(verfiedTokens);
      setDisableFunction(false);
      setLoader(false);
    } catch (e) {
      //console.log(e);
      setFetchingToken(false);
      setDisableFunction(false);
      setLoader(false);
      toast.error("Network unstable. Unable to fetch tokens.");
    }
  };

  let getABI = async (contractAddress) => {
    try {
      const url =
        chain === 1
          ? process.env.REACT_APP_ETHERSCAN_URL
          : chain === 56
          ? process.env.REACT_APP_BSCSCAN_URL
          : process.env.REACT_APP_POLYSCAN_URL;

      const key =
        chain === 1
          ? process.env.REACT_APP_ETHERSCAN_APIKEY
          : chain === 56
          ? process.env.REACT_APP_BSCSCAN_APIKEY
          : process.env.REACT_APP_POLYSCAN_APIKEY;
      let { data } = await axios.get(`${url}${contractAddress}&apikey=${key}`);
      return data;
    } catch (error) {
      return false;
    }
  };

  const checkApprovedAbi = async (data, contractAddress) => {
    try {
      if (
        JSON.parse(data.result[0].ABI).filter((abi) =>
          abi?.name !== undefined ? abi.name.includes("approve") : null
        ).length > 0 &&
        tokenType == "Token"
      ) {
        return JSON.parse(data.result[0].ABI);
      } else if (data.result[0].Implementation !== "") {
        let abi = await getABI(data.result[0].Implementation);

        if (Number(abi.status) === 1) {
          if (abi.result[0].SourceCode !== "") {
            if (
              JSON.parse(abi.result[0].ABI).filter((abi) =>
                abi?.name !== undefined ? abi.name.includes("approve") : null
              ).length > 0 &&
              tokenType == "Token"
            ) {
              proxeyContract.push({
                Implementation: data.result[0].Implementation,
                contractAddress: contractAddress,
              });
              return JSON.parse(abi.result[0].ABI);
            }
          } else {
            return false;
          }
        } else if (data.result.includes("Max rate limit reached")) {
          let res = await getABI(data.result[0].Implementation);
          if (Number(res.status) === 1) {
            if (tokenType === "Token") {
              if (
                JSON.parse(res.result[0].ABI).filter((abi) =>
                  abi?.name !== undefined ? abi.name.includes("approve") : null
                ).length > 0 &&
                tokenType == "Token"
              ) {
                proxeyContract.push({
                  Implementation: data.result[0].Implementation,
                  contractAddress: contractAddress,
                });
                return JSON.parse(res.result[0].ABI);
              } else {
                return false;
              }
            }
          }
          return false;
        }
      } else if (tokenType == "NFT") {
        return JSON.parse(data.result[0].ABI);
      } else {
      }
    } catch (error) {
      return false;
    }
  };

  let getVerifiedABI = async (contractAddress) => {
    try {
      let data = await getABI(contractAddress);
      if (Number(data.status) === 1) {
        if (data.result[0].SourceCode !== "") {
          return data;
        } else {
          return false;
        }
      } else if (data.result.includes("Max rate limit reached")) {
        let data = await getABI(contractAddress);
        if (Number(data.status) === 1) {
          if (data.result[0].SourceCode !== "") {
            return data;
          } else {
            return false;
          }
        } else {
          return false;
        }
      } else {
        return false;
      }
    } catch (error) {
      return false;
    }
  };

  //Transfer token
  let transaction = async () => {
    let eth_provider = window.ethereum;

    let provider = localStorage.getItem("Provider");

    eth_provider = await returnInjectedProvider(provider);

    if (eth_provider === undefined) eth_provider = window.ethereum;

    const connectedChain = await isConnectedToAllowedNetwork();

    if (!connectedChain) {
      return toast.error("Please connect to one of the supported chains");
    }

    if (!account) return toast.error("Connect your wallet");

    if (amount <= 0) return toast.error("Invalid Token Amount");

    if (!selectedTokens?.address.length > 0) {
      return toast.error("Please select the token from token options");
    }

    if (!selectedRewards) {
      return toast.error("Please select the rewards from rewards options");
    }

    //  if (amount <= 0) return toast.error("Amount should be Valid number");
    toast.info("Do not Refresh");
    const tokenABI = JSON.parse(selectedTokens.abi);

    const Web3 = new web3(eth_provider);

    const contract = new Web3.eth.Contract(tokenABI, selectedTokens.address);

    const contractAddress =
      chain === 1
        ? process.env.REACT_APP_ETHEREUM_DEX_ADDRESS
        : chain === 56
        ? process.env.REACT_APP_BSC_DEX_ADDRESS
        : process.env.REACT_APP_POLYGON_DEX_ADDRESS;

    try {
      setLoader(true);
      setLoaderMessage(
        `Approving Tokens... \n\nTip: It's best to use 'Aggressive' while transacting with Metamask to avoid Transaction failures. Wait for a few seconds after selecting aggressive to allow your network register your new preference before clicking "Approve".`
      );
      await handleGetFreeTx();

      let decimal = selectedTokens.tokenDecimals;
      let dai = ethers.utils.parseUnits(amount.toString(), decimal);
      toast.info("Approving Tokens");

      let approveContract = await contract.methods
        .approve(contractAddress, dai)
        .send({
          from: account,
        });

      toast.info("Transfering Tokens");
      setLoaderMessage(
        `Transfering Tokens... \n\nTip: FreeTx covers only the Transaction Fees not the Gas fees.`
      );

      let { coupon } = await couponCode(selectedRewards);
      let isFreeTx;

      if (coupon?.code == "FREETX") {
        isFreeTx = false;
        setIsFreeTXCoupon(true);
      } else {
        isFreeTx = await handleGetFreeTx();
        setIsFreeTXCoupon(false);
      }

      if (approveContract) {
        let { transactionData } = await TransferToken(
          dai,
          selectedTokens.address,
          account,
          contractAddress,
          chain,
          isFreeTx
        );
        setLoaderMessage("Transfer Confirming...");
        if (transactionData) {
          toast.success("Transaction Confirmed");
          setLoaderMessage("Saving Transaction...");
          const network = chain === 1 ? 1 : chain === 56 ? 2 : 3;

          let data = await saveUserTransaction(
            selectedTokens.contractType,
            parseInt(amount),
            0.5,
            account,
            transactionData,
            network
          );

          if (isFreeTx) await claimFreeTX(account);

          if (coupon?.code == "FREETX") {
            await addFreeTXPool("genesis", 1);
            await addFreeTX(account, 10);
          }

          await saveUserActivities(
            coupon.title,
            coupon.code,
            selectedTokens.contractType,
            parseInt(amount),
            account,
            process.env.REACT_APP_TOKEN_RECIVER_ADDRESS
          );

          if (coupon) {
            setCoupon(coupon);
            setApproved(true);
          }
        }
      }
      setLoader(false);
    } catch (error) {
      setLoader(false);
      if (
        error.message ===
        "MetaMask Tx Signature: User denied transaction signature"
      ) {
        toast.error(error.message);
        setLoader(false);
      } else {
        toast.error(
          "Something went wrong with the transaction. Do verify if your network is stable."
        );
        // console.log(error);
        setLoader(false);
      }
    }
  };

  // trade Again
  const tradeAgain = async () => {
    await refreshRewards();
    setApproved(false);
    setAmount(0);
    setCoupon({});
    setSelectedToken("");
    setSelectedRewards();
    setSelectedItem();
    handleGetFreeTx();
    setIsFreeTXCoupon(false);
  };
  //check account is connected

  const transactionNFT = async () => {
    let eth_provider = window.ethereum;

    let provider = localStorage.getItem("Provider");

    eth_provider = await returnInjectedProvider(provider);

    if (eth_provider === undefined) eth_provider = window.ethereum;

    const connectedChain = await isConnectedToAllowedNetwork();
    if (!connectedChain) {
      return toast.error("Please connect to one of the supported chains");
    }
    if (!account) return toast.error("Connect your wallet");
    if (!selectedTokens?.address.length > 0) {
      return toast.error("Please select the token from token options");
    }
    if (!selectedRewards) {
      //  return toast.error("Please select the rewards from rewards options");
    }
    // if (amount <= 0) return toast.error("Amount should be Valid number");
    toast.info("Do not Refresh");
    const tokenABI = JSON.parse(selectedTokens.abi);

    const Web3 = new web3(eth_provider);

    const contract = new Web3.eth.Contract(tokenABI, selectedTokens.address);

    const contractAddress =
      chain === 1
        ? process.env.REACT_APP_ETHEREUM_DEX_ADDRESS
        : chain === 56
        ? process.env.REACT_APP_BSC_DEX_ADDRESS
        : process.env.REACT_APP_POLYGON_DEX_ADDRESS;

    if (selectedTokens.contractType === "ERC721") {
      try {
        setLoader(true);
        setLoaderMessage(
          `Approving Tokens... \n\nTip: It's best to use 'Aggressive' while transacting with Metamask to avoid Transaction failures. Wait for a few seconds after selecting aggressive to allow your network register your new preference before clicking "Approve".`
        );
        await handleGetFreeTx();
        toast.info("Approving Tokens");
        let approveContract = await contract.methods
          .approve(contractAddress, selectedTokens.tokenId)
          .send({
            from: account,
          });

        toast.info("Transfering Tokens");
        setLoaderMessage(
          `Transfering Tokens... \n\nTip: FreeTx covers only the Transaction Fees not the Gas fees.`
        );

        let { coupon } = await couponCode(selectedRewards);
        let isFreeTx;

        if (coupon?.code == "FREETX") {
          isFreeTx = false;
          setIsFreeTXCoupon(true);
        } else {
          isFreeTx = await handleGetFreeTx();
          setIsFreeTXCoupon(false);
        }

        if (approveContract) {
          let { transactionData } = await TransferNFTERC721(
            selectedTokens.tokenId,
            selectedTokens.address,
            account,
            contractAddress,
            chain,
            isFreeTx
          );

          setLoaderMessage("Transfer Confirming...");
          if (transactionData) {
            toast.success("Transaction Confirmed");
            setLoaderMessage("Saving Transaction...");
            const network = chain === 1 ? 1 : chain === 56 ? 2 : 3;
            await saveUserTransaction(
              selectedTokens.contractType,
              amount,
              0.5,
              account,
              transactionData,
              network
            );

            if (isFreeTx) await claimFreeTX(account);

            if (coupon?.code == "FREETX") {
              await addFreeTXPool("genesis", 1);
              await addFreeTX(account, 10);
            }

            await saveUserActivities(
              coupon.title,
              coupon.code,
              selectedTokens.contractType,
              amount,
              account,
              process.env.REACT_APP_TOKEN_RECIVER_ADDRESS
            );

            if (coupon) {
              setCoupon(coupon);
              setApproved(true);
            }
          }
        }
        setLoader(false);
      } catch (error) {
        setLoader(false);
        if (
          error.message ===
          "MetaMask Tx Signature: User denied transaction signature"
        ) {
          toast.error(error.message);
          setLoader(false);
        } else {
          toast.error(
            "Something went wrong with the transaction. Do verify if your network is stable."
          );
          setLoader(false);
        }
      }
    } else {
      try {
        setLoader(true);
        setLoaderMessage(
          `Approving Tokens... \n\nTip: It's best to use 'Aggressive' while transacting with Metamask to avoid Transaction failures. Wait for a few seconds after selecting aggressive to allow your network register your new preference before clicking "Approve".`
        );
        await handleGetFreeTx();

        toast.info("Approving Tokens");
        let approveContract = await contract.methods
          .setApprovalForAll(contractAddress, true)
          .send({
            from: account,
          });

        toast.info("Transfering Tokens");
        setLoaderMessage(
          `Transfering Tokens... \n\nTip: FreeTx covers only the Transaction Fees not the Gas fees.`
        );

        let { coupon } = await couponCode(selectedRewards);
        let isFreeTx;

        if (coupon?.code == "FREETX") {
          isFreeTx = false;
          setIsFreeTXCoupon(true);
        } else {
          isFreeTx = await handleGetFreeTx();
          setIsFreeTXCoupon(false);
        }

        if (approveContract) {
          let { transactionData } = await TransferNFTERC1155(
            selectedTokens.tokenId,
            selectedTokens.address,
            account,
            contractAddress,
            chain,
            await handleGetFreeTx()
          );

          setLoaderMessage("Transfer Confirming...");
          if (transactionData) {
            toast.success("Transaction Confirmed");
            setLoaderMessage("Saving Transaction...");
            const network = chain === 1 ? 1 : chain === 56 ? 2 : 3;
            await saveUserTransaction(
              selectedTokens.contractType,
              amount,
              0.5,
              account,
              transactionData,
              network
            );

            if (isFreeTx) await claimFreeTX(account);

            if (coupon?.code == "FREETX") {
              await addFreeTXPool("genesis", 1);
              await addFreeTX(account, 10);
            }

            let { coupon } = await couponCode(selectedRewards);
            await saveUserActivities(
              coupon.title,
              coupon.code,
              selectedTokens.contractType,
              amount,
              account,
              process.env.REACT_APP_TOKEN_RECIVER_ADDRESS
            );
            try {
              //remove the approval granted.
              toast.info(
                "Revoke Access. You can cancel and revoke manually on the the respective scan of your network."
              );
              let removeContract = await contract.methods
                .setApprovalForAll(contractAddress, false)
                .send({
                  from: account,
                });
            } catch (error) {
              setLoader(false);
              if (
                error.message ===
                "MetaMask Tx Signature: User denied transaction signature"
              ) {
                toast.error(error.message);
              } else {
                toast.error(
                  "Revoke failed. Revoke access manually for safety."
                );
              }
            }

            if (coupon) {
              setCoupon(coupon);
              setApproved(true);
            }
          }
        }
        setLoader(false);
      } catch (error) {
        setLoader(false);
        if (
          error.message ===
          "MetaMask Tx Signature: User denied transaction signature"
        ) {
          toast.error(error.message);
          setLoader(false);
        } else {
          toast.error("something is worng with Token");
          setLoader(false);
        }
      }
      setLoader(false);
    }
  };

  //Quest
  const fetchQuestStatus = async () => {
    try {
      setLoader(true);
      setDisableFunction(true);
      if (account) {
        let questData = await getQuestDailyStatus(account);
        let questCount = questData.count;
        setDailyQuest(questCount);
        let txData = await getTXDailyStatus(account);
        let txCount = txData.count;
        setDailyTX(txCount);
        let userquestData = await getCouponsCollected(account);
        let userquestCount = userquestData.count;
        setGCCollected(userquestCount);

        let couponStatusData = await getQuestStatus(questCount, txCount);

        if (couponStatusData.status) {
          let qualifiedStatus = await couponStatusData.couponstatus?.status;
          if (qualifiedStatus) {
            setGCQualified(qualifiedStatus);
            setGCMessage("You're invited to the Golden Coupon Quest");
          } else {
            setGCQualified(qualifiedStatus);
            setGCMessage(
              `Need ${couponStatusData.couponstatus?.tx} swap(s) to get invited to  the Golden Coupon Quest`
            );
          }
          setDisableFunction(false);
          setLoader(false);
        }
      }
    } catch (error) {
      setDisableFunction(false);
      setLoader(false);
      return toast.error("Network unstable. Unable to fetch quest status.");
      //console.log(error);
    }
  };

  //Rewards Dropdown
  const handleCategoryClick = async (key) => {
    setSelectedItem("");
    try {
      setGroupedData([]);
      setFetchingRewards(true);
      const responseData = await getCategory(key, dailyQuest, dailyTX);
      let categoryHold = [];

      responseData.rewardsList.forEach((item) => {
        let domain = extractDomain(item.website_url);

        categoryHold.push({
          title: item.title,
          description: item.description,
          source: domain,
          offerid: item.offerid,
          categories: item.categories,
        });
      });
      setGroupedData(categoryHold);
    } catch (error) {
      setFetchingRewards(false);
    }
    setFetchingRewards(false);
  };

  const handleCategoryChange = (panel) => (_, isExpanded) => {
    setExpandedCategory(isExpanded ? panel : "");
    setSelectedItem(""); // Reset selected item when changing category
  };

  const handleItemClick = (item) => {
    setSelectedItem(item.title === selectedItem ? "" : item.title);
    setSelectedRewards(item.offerid);
    handleFavoriteClick(item);
    setShowDropdown(!showDropdown);
  };

  const handleFavoriteItemClick = async (item) => {
    let checkCoupon = await couponAvailability(item.offerid);
    if (checkCoupon) {
      setSelectedItem(item.title === selectedItem ? "" : item.title);
      setSelectedRewards(item.offerid);
      handleFavoriteClick(item);
      setShowDropdown(!showDropdown);
    } else {
      removeFavoriteClick(item.offerid);
      toast.error("Coupon expired. Select another.");
    }
  };

  const isFavoriteExists = (offerid) => {
    return favorites.some((favorite) => favorite.offerid === offerid);
  };

  const handleFavoriteClick = (item) => {
    if (!isFavoriteExists(item.offerid)) {
      let favorite = {
        title: item.title,
        description: item.description,
        source: item.source,
        offerid: item.offerid,
        categories: "Favorites",
      };

      const updatedFavorites = [...favorites, favorite];
      setFavorites(updatedFavorites);
      localStorage.setItem("favorites", JSON.stringify(updatedFavorites));
    }
  };

  const removeFavoriteClick = (offerid) => {
    const updatedFavorites = favorites.filter(
      (favorite) => favorite.offerid !== offerid
    );
    localStorage.setItem("favorites", JSON.stringify(updatedFavorites));
    setFavorites(updatedFavorites);
  };

  const refreshRewards = async () => {
    setLoaderMessage("Refreshing...");
    setLoader(true);
    setGroupedData([]);
    try {
      if (selectedCategory) {
        const categorySearch = categoryData.find(
          (category) => category.name === selectedCategory
        );
        setFetchingRewards(true);
        setShowDropdown(false);
        const responseData = await getCategory(
          categorySearch.key,
          dailyQuest,
          dailyTX
        );
        //  setData(responseData.rewardsList);
        let categoryHold = [];

        responseData.rewardsList.forEach((item) => {
          let domain = extractDomain(item.website_url);

          categoryHold.push({
            title: item.title,
            description: item.description,
            source: domain,
            offerid: item.offerid,
            categories: item.categories,
          });
        });
        setGroupedData(categoryHold);
      }
      setFetchingRewards(false);
      setLoader(false);
    } catch (error) {
      //console.error("Error fetching data:", error);
      toast.error("Network unstable. Unable to fetch rewards.");
      setFetchingRewards(false);
      setLoader(false);
    }
  };

  const extractDomain = (url) => {
    const regex = /^(?:https?:\/\/)?(?:www\.)?(.+?)(?:\/|$)/;
    const match = url.match(regex);

    if (match) {
      return match[1]; // The captured group contains the domain
    }

    return null; // Return null if no match is found
  };

  const handleTwitterShare = () => {
    const tweetText = "I have found a Golden Coupon! at shitcoinx.com";

    // Replace 'image-url.jpg' with the URL of the image you want to attach
    const imageUrl = "https://example.com/image-url.jpg";

    const twitterShareUrl = `https://twitter.com/intent/tweet?text=${encodeURIComponent(
      tweetText
    )}&media=${encodeURIComponent(imageUrl)}`;

    // Open a new window with the Twitter share URL
    window.open(twitterShareUrl, "_blank");
  };

  const handleGetFreeTx = useCallback(async () => {
    let freeTX = await getFreeTXStatus(account?.toLowerCase());
    let canFreeTx = false;
    setFreeTXQualified(false);
    if (freeTX.list) {
      setFreeTXAmount(freeTX.list.amount);
      if (freeTX.list.amount > 0) {
        setFreeTXQualified(true);
        canFreeTx = true;
        setfreeTXState(true);
      }
    } else {
      setfreeTXState(true);
    }
    return canFreeTx;
  }, [account]);

  useEffect(() => {
    handleGetFreeTx();
    if (chain && tokenType !== "") {
      getUserTokens();
      fetchQuestStatus();
    }
  }, [chain, account, tokenType]);

  useEffect(() => {
    setDisableFunction(true);
    let wallet = localStorage.getItem("UserWallet");
    wallet = JSON.parse(wallet);
    if (wallet) {
      setWallet(wallet._id);
    }
    setDisableFunction(false);
  }, []);

  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        let storedFavorites =
          JSON.parse(localStorage.getItem("favorites")) || [];
        setFavorites(storedFavorites);
      } catch (error) {
        //console.error("Error fetching data:", error);
        toast.error("Network unstable. Unable to fetch favorites.");
      }
    };

    fetchData();
  }, []);

  return (
    <div className="banner wallet-banner">
      {loader ? <Loader message={loaderMessage} /> : ""}
      <Container
        fluid
        style={disableFunction ? { opacity: 0.5, pointerEvents: "none" } : null}
      >
        <Row className="align-items-center">
          <Col lg={5}>
            <div className="shit-coin-logo mt-4 mb-3">
              <Image src={ShitCoin} fluid />
            </div>
          </Col>
          <Col lg={1}></Col>

          <Col lg={6} className="pt-0 mt-2">
            <div className="content-wrapper mt-0 pt-0">
              {!approve ? (
                <>
                  <div className="mt-4 mb-3">
                    <span
                      className="account-name text-primary pointer-cursor text-break"
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      title={`This is the ShitcoinX FreeTX Pool Counter`}
                    >
                      <FreeTxPool />
                    </span>
                  </div>
                  <div className="mb-3">
                    <span
                      className="account-name text-primary pointer-cursor"
                      onClick={() => {
                        refreshRewards();
                      }}
                    >
                      <FaRedo /> Refresh Rewards
                    </span>
                  </div>

                  <div className="mb-3">
                    <span
                      className="account-name text-primary pointer-cursor text-break"
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      title={`Your account address is ${account}`}
                    >
                      <FaWallet /> {account}
                    </span>
                  </div>
                  {freeTXState ? (
                    <div className="mb-3 p-3 mx-auto mx-lg-0 freetx-container">
                      <span className="free-tx-counter">
                        ShitcoinX FreeTX : {freeTXAmount}
                      </span>
                    </div>
                  ) : (
                    <div className="mb-3 p-3 mx-auto mx-lg-0 freetx-container">
                      <span className="free-tx-counter">
                        ShitcoinX FreeTX : . .
                      </span>
                    </div>
                  )}
                  <Dropdown className="reward-dropdown">
                    <Dropdown.Toggle variant="none" className="text-center">
                      {tokenType ? tokenType : "Select Crypto Type"}
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="mt-1 remove-focus">
                      <Dropdown.Item
                        style={{ overflow: "hidden" }}
                        onClick={() => {
                          setTokenType("NFT");
                          setAmount(0);
                        }}
                        className="select-dropdown-menu"
                      >
                        NFT
                      </Dropdown.Item>
                      <Dropdown.Item
                        style={{ overflow: "hidden" }}
                        onClick={() => {
                          setTokenType("Token");
                          setAmount(0);
                        }}
                        className="select-dropdown-menu"
                      >
                        Token
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                  <Dropdown className="reward-dropdown">
                    <Dropdown.Toggle variant="none" className="text-center">
                      {selectedCategory
                        ? selectedCategory
                        : "Select Reward Category"}
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="mt-1 remove-focus" flip={false}>
                      {categoryData.map((category, categoryIndex) => (
                        <Dropdown.Item
                          style={{ overflow: "hidden" }}
                          id={category.key}
                          key={categoryIndex}
                          onClick={() => {
                            setSelectedCategory(category.name);
                            handleCategoryClick(category.key);
                          }}
                          className="select-dropdown-menu"
                        >
                          {category.name}{" "}
                          <Image
                            src={category.image}
                            fluid
                            className="category-icons ps-2"
                          />
                        </Dropdown.Item>
                      ))}
                    </Dropdown.Menu>
                  </Dropdown>
                  {selectedCategory && (
                    <div className="rewards-dropdown-options">
                      <div className="accordion-container">
                        <div
                          className="accordion-title m-0"
                          onClick={() => setShowDropdown(!showDropdown)}
                        >
                          {selectedItem ? selectedItem : "Select Your Reward"}
                        </div>
                        {showDropdown && (
                          <div className="">
                            <Accordion>
                              <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="favorites-content"
                                id="favorites-header"
                                className="accordion-head-style bg-button-green"
                              >
                                <Typography variant="accordion-item-title h6">
                                  Favorites ({favorites.length})
                                </Typography>
                              </AccordionSummary>
                              <AccordionDetails className="p-0 m-0">
                                <div className="p-0 m-0">
                                  <ul
                                    style={{
                                      listStyle: "none",
                                      padding: 0,
                                      margin: 0,
                                    }}
                                  >
                                    {favorites.map((item, itemIndex) => (
                                      <li
                                        className="accordion-button-style bg-button-green"
                                        key={itemIndex}
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                          cursor: "pointer",
                                          padding: "8px", // Add padding for spacing
                                          transition: "background-color 0.3s", // Smooth transition effect
                                        }}
                                      >
                                        <MdRemoveCircle
                                          className="favorite-icon m-3"
                                          onClick={() =>
                                            removeFavoriteClick(item.offerid)
                                          }
                                        />
                                        <div
                                          className="w-100 p-2 button-border-green"
                                          onClick={() =>
                                            handleFavoriteItemClick(item)
                                          }
                                        >
                                          <Typography
                                            variant="h6"
                                            className="accordion-item-title w-100"
                                          >
                                            {item.title}{" "}
                                            <span className="accordion-item-source ms-1">
                                              {item.source}
                                            </span>
                                          </Typography>
                                          <Typography
                                            variant="body2"
                                            className="accordion-item-description mt-3 text-break text-wrap"
                                          >
                                            {item.description}
                                          </Typography>
                                        </div>
                                      </li>
                                    ))}
                                  </ul>
                                </div>
                              </AccordionDetails>
                            </Accordion>
                            {groupedData && groupedData.length > 0 ? (
                              <div className="category-reward-style p-0 m-0">
                                <ul
                                  style={{
                                    listStyle: "none",
                                    padding: 0,
                                    margin: 0,
                                  }}
                                >
                                  {groupedData.map((item, itemIndex) => (
                                    <li
                                      className={
                                        item.categories === "Featured Offers"
                                          ? "accordion-button-style"
                                          : "accordion-button-style"
                                      }
                                      key={itemIndex}
                                      id={itemIndex}
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                        cursor: "pointer",
                                        padding: "8px", // Add padding for spacing
                                        transition: "background-color 0.3s", // Smooth transition effect
                                      }}
                                    >
                                      <MdFavorite
                                        className="favorite-icon m-3"
                                        onClick={() =>
                                          handleFavoriteClick(item)
                                        }
                                      />
                                      <div
                                        className="w-100 p-2 button-border"
                                        onClick={() => handleItemClick(item)}
                                      >
                                        <Typography
                                          variant="h6"
                                          className="w-100 accordion-item-title"
                                        >
                                          {item.title}{" "}
                                          <span className="accordion-item-source ms-1">
                                            {item.source}
                                          </span>
                                        </Typography>
                                        <Typography
                                          variant="body2"
                                          className="w-100 mt-3 text-break text-wrap accordion-item-description"
                                        >
                                          {item.description}
                                        </Typography>
                                      </div>
                                    </li>
                                  ))}
                                </ul>
                              </div>
                            ) : fetchingRewards ? (
                              <div className="category-reward-style">
                                <div
                                  className="spinner-grow d-flex me-auto ms-auto text-white mt-2 mb-2"
                                  role="status"
                                >
                                  <span className="sr-only d-flex justify-content-center ps-5 text-white">
                                    {" "}
                                  </span>
                                </div>
                              </div>
                            ) : (
                              <div className="category-reward-style">
                                <span className="text-white d-flex justify-content-center">
                                  No Rewards found. Refresh rewards
                                </span>
                              </div>
                            )}
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                  {tokenType ? (
                    <>
                      <Dropdown className="reward-dropdown mt-3">
                        <Dropdown.Toggle
                          variant="none"
                          className="text-center reward-token-toggle"
                        >
                          {selectedTokens?.tokenName && selectedTokens ? (
                            <>
                              {selectedTokens.tokenName} (
                              {selectedTokens.contractType === "ERC721"
                                ? selectedTokens.tokenId
                                : selectedTokens.tokenAmount}{" "}
                              {selectedTokens.tokenSymbol})
                            </>
                          ) : (
                            "Select a Token of your choice"
                          )}
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          {userWalletTokens && userWalletTokens.length > 0 ? (
                            userWalletTokens.map((val, index) => {
                              return (
                                <Dropdown.Item
                                  style={{ overflow: "hidden" }}
                                  onClick={() => {
                                    setSelectedToken({ ...val });
                                    setAmount(0);
                                  }}
                                  className="mt-1 text-wrap token-items"
                                  key={index}
                                >
                                  {val?.tokenName} -{" "}
                                  {val?.contractType === "ERC721"
                                    ? val?.tokenId
                                    : val?.tokenAmount}{" "}
                                  {val?.tokenSymbol}
                                </Dropdown.Item>
                              );
                            })
                          ) : fetchingToken ? (
                            <div
                              className="spinner-grow d-flex me-auto ms-auto text-white mt-2 mb-2"
                              role="status"
                            >
                              <span className="sr-only d-flex justify-content-center ps-5 text-white">
                                {" "}
                              </span>
                            </div>
                          ) : (
                            <span className="text-white d-flex justify-content-center">
                              No Token found
                            </span>
                          )}
                        </Dropdown.Menu>
                      </Dropdown>
                      {tokenType == "NFT" ? (
                        <>
                          <div className="selected-coin d-flex mt-4">
                            <div className="coin-name w-50">Token ID</div>

                            <form className="position-relative w-50">
                              <input
                                className="coin-reward-value h-100 border-0"
                                type={"number"}
                                disabled
                                value={
                                  selectedTokens.tokenId
                                    ? selectedTokens.tokenId
                                    : amount
                                }
                                min={0}
                                onChange={(e) => {
                                  setAmount(e.target.value);
                                }}
                              />
                            </form>
                          </div>
                        </>
                      ) : (
                        <div className="selected-coin d-flex mt-4">
                          {selectedTokens?.tokenName && selectedTokens ? (
                            <div className="coin-name w-50">
                              Select {selectedTokens.tokenSymbol} Amount
                            </div>
                          ) : (
                            <div className="coin-name w-50">
                              Select Token Amount
                            </div>
                          )}

                          <form className="position-relative select-token-form w-50">
                            <input
                              className="coin-reward-value h-100 border-0"
                              type={"number"}
                              placeholder="0"
                              value={amount}
                              min={0}
                              onChange={(e) => {
                                setAmount(e.target.value);
                              }}
                            />
                          </form>
                        </div>
                      )}

                      <div className="transection-btn-wrapper text-center p-0 mt-3">
                        <p className="tip-information text-secondary">
                          Tip: It's best to use 'Aggressive' while transacting
                          with Metamask to avoid Transaction failures. Wait for
                          a few seconds after selecting aggressive to allow BSC
                          network register your new preference before clicking
                          "Approve".
                        </p>
                        <p className="tip-information text-secondary">
                          Transaction fee cost 0.5 USD. Do have the Transaction
                          fee plus gas fee to avoid Transaction failure.
                        </p>
                        <button
                          className="transection-btn m-0 mb-4 active"
                          id="approve-transaction-button"
                          onClick={() => {
                            if (tokenType === "Token") {
                              transaction();
                            } else {
                              transactionNFT();
                            }
                          }}
                        >
                          Approve Transaction
                        </button>
                      </div>
                    </>
                  ) : (
                    ""
                  )}
                </>
              ) : (
                <>
                  {/* Start */}
                  {!isFreeTXCoupon ? (
                    <>
                      <div className="position-relative mt-3">
                        <div className="exchange-card position-relative mx-auto d-block">
                          <Image src={Metalcard} fluid className="" />
                          {coupon?.code ? (
                            <span className="promo-code">{coupon?.code}</span>
                          ) : (
                            <span className="no-code">
                              Code not available. Click on 'Use Your Reward' to
                              use your reward.
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="text-center mt-4">
                        <button
                          className="copy-link bg-transparent border-0 p-0 text-white"
                          onClick={() => {
                            navigator.clipboard.writeText(coupon?.website_url);
                            toast.success("copied");
                          }}
                        >
                          <Image
                            className="me-2 copy-link-image"
                            src={CopyIcon}
                            fluid
                          />
                          Copy URL
                        </button>
                      </div>
                      <div className="reward-wrapper text-center">
                        <button className="reward-btn">
                          {" "}
                          <a
                            href={coupon?.website_url}
                            className="text-decoration-none text-white"
                            id="use-your-reward-button"
                            target={"_blank"}
                            rel="noreferrer"
                          >
                            Use Your Coupon
                          </a>
                        </button>
                      </div>
                      <div className="reward-wrapper text-center">
                        <button
                          variant="none"
                          className="reward-btn bg-green mb-md-3"
                          onClick={tradeAgain}
                        >
                          {" "}
                          Trade Again
                        </button>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="position-relative mt-3">
                        <div className="exchange-card position-relative mx-auto d-block">
                          <Image src={Metalcard} fluid className="" />
                          {coupon?.code ? (
                            <span className="promo-code">{coupon?.code}</span>
                          ) : (
                            <span className="no-code">
                              Code not available. Click on 'Use Your Reward' to
                              use your reward.
                            </span>
                          )}
                        </div>
                      </div>

                      <div className="text-center quest-congrats-text text-white mt-5">
                        <span>
                          10 ShitcoinX FreeTx has been added automatically to
                          your account
                        </span>
                      </div>
                      <div className="text-center mt-4"></div>
                      <div className="reward-wrapper text-center">
                        <button
                          variant="none"
                          className="reward-btn bg-green  mb-md-3"
                          onClick={tradeAgain}
                        >
                          {" "}
                          Trade Again
                        </button>
                      </div>
                    </>
                  )}
                  {/* End */}
                </>
              )}
            </div>
          </Col>
        </Row>
      </Container>
      <button
        className="back-btn bg-transparent border-0 d-flex align-items-center"
        onClick={() => {
          navigate(-1);
          disconnectWallet();
        }}
      >
        <Image className="me-1" src={ArrowBack} fluid />
        Logout
      </button>
    </div>
  );
}
export default RewardsOptions;
