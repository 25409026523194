import { createContext, useContext, useState, useEffect } from "react";

// hooks
import useSwitch from "../hooks/useSwitch";
import useForceUpdate from "../hooks/useForceUpdate";
import usePersistentToast from "../hooks/usePersistentToast";

// libraries
import { toast } from "react-toastify";
import { Web3Provider } from "@ethersproject/providers";
import detectEthereumProvider from "@metamask/detect-provider";

// helpers
import Web3 from "../helpers/web3";
import {
  activateInjectedProvider,
  returnInjectedProvider,
} from "../helpers/activateinjectedprovider";
import { useAccount, useConnect, useDisconnect, useBalance } from "wagmi";

// config
import { allowedChains } from "../utils/config";

// axios
import { userWallet, removeWallet, setProvider } from "../utils/axios";

//connectors
import { injected, CoinbaseWallet, walletconnect } from "../utils/connectors";

const { ethereum } = window;

const Web3ConnectContext = createContext({
  account: "",
  chain: "",
  disconnectWallet: () => {},
  connectWallet: () => {},
  isConnectedToAllowedNetwork: async () => false,
  handleTransactionError: (err) => {},
  refresh: {
    rerender: () => {},
    triggerValue: 0,
  },
});

export const useWeb3Connect = () => useContext(Web3ConnectContext);

const isConnectedToAllowedNetwork = async () => {
  let eth_provider = window.ethereum;

  let provider = localStorage.getItem("Provider");

  eth_provider = await returnInjectedProvider(provider);

  if (eth_provider === undefined) eth_provider = window.ethereum;

  let chainID = 1;

  if (provider == "WalletConnect") {
    chainID = 1;
  } else {
    chainID = parseInt(await eth_provider.request({ method: "eth_chainId" }));
  }

  return !(
    allowedChains.length > 0 &&
    !allowedChains.find((chain) => chain.id === chainID)
  );
};

const Web3ConnectProvider = ({ children }) => {
  const forceUpdate = useForceUpdate();
  const [account, setAccount] = useState("");
  const [chain, setChain] = useState();
  const isTransactionErrorModalOpen = useSwitch();
  const { address, connector, status, isConnected } = useAccount();
  const { connect, connectors, isLoading } = useConnect();
  const { disconnect } = useDisconnect();
  const { data } = useBalance();
  //  let provider = window.ethereum;

  const persistentSwitchChainToast = usePersistentToast(
    "Please connect to one of the supported chains",
    "error"
  );

  const persistentWeb3BrowserToast = usePersistentToast(
    "Ensure you are using a Web3 enabled browser",
    "error"
  );

  let isConnectedWallet = async () => {
    let eth_provider = window.ethereum;

    let provider = localStorage.getItem("Provider");

    //  let chainId = 1;
    //  await walletconnect.connect({chainId});
    //  provider = await walletconnect.getProvider({chainId});

    eth_provider = await returnInjectedProvider(provider);

    if (eth_provider === undefined) eth_provider = window.ethereum;

    //   console.log(provider);

    // console.log(eth_provider);

    const accounts = await eth_provider.request({ method: "eth_accounts" });

    if (accounts.length) {
      setAccount(accounts[0]);
      return true;
    } else {
      return false;
    }
  };

  const connectWallet = async (chainId, wallettype) => {
    switch (wallettype) {
      case "metamask":
        try {
          let provider = window.ethereum;



          if (typeof window.ethereum !== "undefined") {
      
           provider = await returnInjectedProvider("MetaMask");

          //  console.log(provider);

            if (provider === undefined) provider = window.ethereum;


            if (![chainId].includes(allowedChains)) {
              await provider.request({
                method: "wallet_switchEthereumChain",
                params: [{ chainId: `0x${chainId.toString(16)}` }],
              });
            }
     
            const accounts = await provider.request({
              method: "eth_requestAccounts",
              params: [],
            });

            setProvider("MetaMask");

            setAccount(accounts[0]);
          } else if ("ethereum#initialized") {
            window.location = process.env.REACT_APP_METAMASK_URL;
            await ethereum.request({
              method: "wallet_switchEthereumChain",
              params: [{ chainId: `0x${chainId.toString(16)}` }],
            });
          }

          const chain = parseInt(
            await provider.request({ method: "eth_chainId" })
          );

          setChain(chain);
        } catch (e) {
          switch (e.code) {
            case 4001:
              toast.info("Please connect to Metamask");
              break;
            case -32002:
              toast.info("Please open Metamask");
              break;
            case e:
              toast.info("Please switch to BSC network");
              break;
            default:
          }
        }
        break;

      case "coinbase":
        try {
          let provider = window.ethereum;

          activateInjectedProvider("CoinBase");

          provider = await returnInjectedProvider("CoinBase");

          if (provider === undefined) provider = window.ethereum;

          //console.log("Wallet connect triggered");

          //await activate(injected, undefined, true);
          await injected.connect(chainId);

          // console.log(provider);

          await CoinbaseWallet.connect(chainId);

          //  console.log("clicked 1");

          const accountCoinbase = await CoinbaseWallet.getAccount();
          const chainIdCoinbase = await CoinbaseWallet.getChainId();
          const providerAcc = await CoinbaseWallet.getProvider();
          // setConnectorName("Wallet Connect");
          // setWcAccount(accountWC);
          // setWcChainId(chainIdWC);
          setProvider("CoinBase");
          //  setAccount(accounts[0]);
          setChain(chainIdCoinbase);

          // console.log(providerAcc);

          if (![chainId].includes(allowedChains)) {
            console.log("Entered");
            await provider.request({
              method: "wallet_switchEthereumChain",
              params: [{ chainId: `0x${chainId.toString(16)}` }],
            });
            console.log("Exited");
          }
        } catch (e) {
          switch (e.code) {
            case 4001:
              toast.info("Please connect to Coinbase Wallet");
              break;
            case -32002:
              toast.info("Please open Coinbase Wallet");
              break;
            case e:
              toast.info("Please switch to the right network");
              break;
            default:
          }
        }
        break;

      case "walletconnect":
        try {
          let providerWallet = await walletconnect.connect({ chainId });
          //connect({ connector: connectors[1] })
          const accountWC = providerWallet.account;
          const chainIdWC = await walletconnect.getChainId();
          const providerWc = await walletconnect.getProvider({ chainId });
          const result = await providerWc.request({ method: "eth_accounts" });
          setProvider("WalletConnect");
          setChain(chainIdWC);
          setAccount(accountWC);
          //  console.log(providerWallet);
          //  console.log(providerWc);
          //  console.log(providerWc.isWalletConnect);
          //  console.log(providerWc.connected);
          //  console.log(result);
          //  console.log(status);
          //   console.log(connectedStatus);
        } catch (e) {
          switch (e.code) {
            case 4001:
              toast.info("Please connect to Walletconnect Wallet");
              break;
            case -32002:
              toast.info("Please open Walletconnect Wallet");
              break;
            case e:
              toast.info("Please switch to the right network");
              break;
            default:
          }
        }
        break;

      default:
    }
  };

  const disconnectWallet = async () => {
    await walletconnect.disconnect();
    disconnect();
    setAccount("");
    setChain("");
    removeWallet();
    forceUpdate.rerender();
  };

  const refresh = async () => {
    forceUpdate.rerender();
    let eth_provider = window.ethereum;

    let provider = localStorage.getItem("Provider");

    eth_provider = await returnInjectedProvider(provider);

    if (eth_provider === undefined) eth_provider = window.ethereum;

    let chain = 1;

    if (provider == "WalletConnect") {
      chain = 1;
    } else {
      chain = parseInt(await eth_provider.request({ method: "eth_chainId" }));
    }

    setChain(chain);
    if (await isConnectedToAllowedNetwork())
      return persistentSwitchChainToast.dismiss();
    persistentSwitchChainToast.trigger();
  };

  const handleTransactionError = (err) => {
    const fallbackMessage = `Something went wrong. Please check the transaction in the explorer.`;

    switch (err.code) {
      case 4001:
        toast.error("Transaction was rejected by the user.");
        return;

      default:
        if (err.message) {
          try {
            const substring = err.message.substring(
              err.message.indexOf("{"),
              err.message.lastIndexOf("}") + 1
            );
            const errorObject = JSON.parse(substring);
            const errorMessage =
              errorObject.originalError?.message || errorObject.value?.message;
            return toast.error(
              errorMessage.charAt(0).toUpperCase() +
                errorMessage.substr(1, errorMessage.length - 1)
            );
          } catch (error) {
            isTransactionErrorModalOpen.true();
          }
        } else {
          toast.error(fallbackMessage);
          return;
        }
    }
  };

  useEffect(() => {
    const init = async () => {
      //   console.log("clicked effect");

      let provider = localStorage.getItem("Provider");

      let eth_provider = window.ethereum;

      // console.log(window.ethereum);

      //  console.log(isConnected);

      if (provider != undefined) {
        eth_provider = await returnInjectedProvider(provider);

        if (eth_provider === undefined) eth_provider = window.ethereum;

       // console.log(eth_provider);

        let chainID = 1;

        if (provider == "WalletConnect") {
          chainID = 1;
        } else {
          chainID = parseInt(
            await eth_provider.request({ method: "eth_chainId" })
          );
        }

        setChain(chainID);

       // console.log(chainID);

        if (!Web3.isEnabled()) return persistentWeb3BrowserToast.trigger();
        if (!(await isConnectedToAllowedNetwork()))
          persistentSwitchChainToast.trigger();
        isConnectedWallet();
        eth_provider.on("chainChanged", refresh);
        eth_provider.on("accountsChanged", (accounts) => {
          setAccount(accounts[0] || "");
          //  setChain(chainID);
          if (accounts[0]) {
            toast.success("Wallet is connected");
          } else {
            toast.info("Account is disconnected");
          }
        });
      } else {
        disconnectWallet();
      }
    };
    init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (account.length > 0) {
      userWallet(account);
    } else {
    }
  }, [account]);

  const value = {
    account,
    connectWallet,
    chain,
    disconnectWallet,
    isConnectedToAllowedNetwork,
    handleTransactionError,
    refresh: { rerender: refresh, triggerValue: forceUpdate.triggerValue },
  };

  return (
    <>
      <Web3ConnectContext.Provider value={value}>
        {children}
      </Web3ConnectContext.Provider>
    </>
  );
};

export default Web3ConnectProvider;
