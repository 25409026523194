import "./rewardsdropdown.css";
import { Col, Container, Row, Image } from "react-bootstrap";
import React, { useState, useEffect } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";


function RewardsDropdown() {
  const [data, setData] = useState([]);
  const [expandedCategory, setExpandedCategory] = useState("");
  const [selectedItem, setSelectedItem] = useState("");
  const [showDropdown, setShowDropdown] = useState(false);
  const [favorites, setFavorites] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const handleCategoryChange = (panel) => (_, isExpanded) => {
    setExpandedCategory(isExpanded ? panel : "");
    setSelectedItem(""); // Reset selected item when changing category
  };

  const handleItemClick = (item) => {
    setSelectedItem(item === selectedItem ? "" : item);
    setShowDropdown(!showDropdown);
  };

  
  const groupedData = {};
 

  data.forEach((item) => {
    let categories = item.categories;

    // Check the length of the category name and assign to "Other Categories" if it's above 40 or empty
    if (!categories || categories.length > 40) {
      categories = "Other Categories";
    }

    if (!groupedData[categories]) {
      groupedData[categories] = [];
    }
    groupedData[categories].push(item);
  });

  const favoriteData = () => {
    const _favoriteData = {};

    data.forEach((item) => {
      let categories = item.categories;
  
      // Check the length of the category name and assign to "Other Categories" if it's above 40 or empty
      if (!categories || categories.length > 40) {
        categories = "Other Categories";
      }
  
      if (!groupedData[categories]) {
        groupedData[categories] = [];
      }
      groupedData[categories].push(item);
    });

  }

  const sortedCategories = Object.keys(groupedData).sort((a, b) => {
    if (a === "Special Offers") return -1;
    if (b === "Special Offers") return 1;
    if (a === "Other Categories") return 1;
    if (b === "Other Categories") return -1;
    return a.localeCompare(b);
  });

  return (
    <div className="accordion-container">
      <div
        className="accordion-title"
        onClick={() => setShowDropdown(!showDropdown)}
      >
        {selectedItem ? selectedItem : "Select Your Reward"}
      </div>
      {showDropdown && (
        <div>
          {sortedCategories.map((category, index) => (
            <Accordion
              key={index}
              expanded={expandedCategory === category}
              onChange={handleCategoryChange(category)}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls={`${category}-content`}
                id={`${category}-header`}
                className="accordion-head-style"
              >
                <Typography variant="h6">
                  {category} ({groupedData[category].length})
                </Typography>
              </AccordionSummary>
              <AccordionDetails className="p-0 m-0 mb-2">
                <div className="p-0 m-0">
                  <ul style={{ listStyle: "none", padding: 0, margin: 0 }}>
                    {groupedData[category].map((item, itemIndex) => (
                      <li
                        className="accordion-button-style"
                        key={itemIndex}
                        style={{
                          display: "flex",
                          alignItems: "center",
                          cursor: "pointer",
                          padding: "8px", // Add padding for spacing
                          transition: "background-color 0.3s", // Smooth transition effect
                        }}
                        onClick={() => handleItemClick(item.title)}
                      >
                        <div>
                          <Typography variant="body1">{item.title}</Typography>
                          <Typography variant="caption">
                            {item.description}
                          </Typography>
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>
              </AccordionDetails>
            </Accordion>
          ))}
        </div>
      )}
    </div>
  );
}

export default RewardsDropdown;
