import "./Quest.css";
import { Col, Container, Row, Image } from "react-bootstrap";
import Plena from "../../../assets/images/plena.png";
import Shitty from "../../../assets/images/shitty.png";
import Shitcoin from "../../../assets/images/shitcoin-gc4.png";
import { FaCaretRight } from "react-icons/fa";

function Quest() {
  return (
    <div className="quest">
      {/* 
      <Container fluid className="custom-container mt-4 mb-5">
        <Row>
          <Col
            lg={3}
            className="d-flex align-items-center justify-content-center mx-auto mt-4"
          >
            <Image src={Plena} fluid className="quest-img d-block mx-auto" />
          </Col>
          <Col lg={7} className="mx-auto mt-4">
            <div className="quest-info-container">
              <h2 className="quest-header text-center mb-3 mb-md-5">
                Trade for free with your <br />
                <span className="quest-header-span plena-bg">
                  Plena Wallet{" "}
                </span>
              </h2>

              <div className="quest-text-col plena-text text-center mb-5">
                <p>
                  The Plena App is a cutting-edge crypto super app that combines
                  the security of self-custodial wallets with the convenience of
                  a centralized application. It allows users to invest in over
                  100,000 cryptocurrencies.
                </p>
                <p>
                  Thanks to Plena Finance partnership with ShitcoinX, you get to
                  swap your tokens without using FreeTx or paying for ShitcoinX
                  transaction fees..{" "}
                </p>
                <a
                  className="mt-2 text-decoration-none"
                  href="https://shitcoinx.gitbook.io/docs/plena-wallet"
                  target="_blank"
                  id="cta-quest-lore"
                >
                  Discover more about Plena
                </a>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
      */}
      <Container fluid className="custom-container mt-4 mb-5">
        <Row>
          <Col
            lg={3}
            className="d-flex align-items-center justify-content-center mx-auto mt-4"
          >
            <Image
              src={Shitty}
              fluid
              className="quest-img quest-img-shitty d-block mx-auto"
            />
          </Col>
          <Col lg={7} className="mx-auto mt-4">
            <div className="quest-info-container">
              <h2 className="quest-header text-center mb-3 mb-md-5">
                Revalue Dead Tokens with <br />
                <span className="quest-header-span shitcoinx-bg">
                  Shitcoinx FreeTX Coupon
                </span>
              </h2>

              <div className="quest-text-col shitcoinx-text text-center mb-5">
                <p>
                  You can now unlock real value from your rugged tokens with the
                  ShitcoinX Redemption pool. Gain access to the redemption pool
                  by holding at least 1 ShitcoinX FreeTX Coupon. The redemption
                  pool allows you to swap your ShitcoinX FreeTX for other
                  project tokens and redeem some or more of it’s original value.
                </p>
                <p>
                Redemption Pool unlocks at the end of our First Epoch on a FCFS basis to qualified wallets.
                </p>
                <a
                  className="mt-2 text-decoration-none"
                  href="https://shitcoinx.gitbook.io/docs/shitcoinx-free-transactions-tx-model/how-to-earn-free-tx"
                  target="_blank"
                  id="cta-quest-lore"
                >
                  Read more about ShitcoinX FreeTx
                </a>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default Quest;
