import React, { useEffect, useState, useCallback, useRef } from "react";
import "./SolanaChain.css";
import { Container, Row, Col, Image, Dropdown } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import ShitCoin from "../../assets/images/shitcoin-gc3.png";
import { FaInfoCircle, FaWallet, FaRedo } from "react-icons/fa";
import { MdFavorite, MdRemoveCircle, MdShield } from "react-icons/md";
import { HiTicket } from "react-icons/hi";
import ArrowBack from "../../assets/images/arrow-back.svg";
import Goldcard from "../../assets/images/gold.png";
import Metalcard from "../../assets/images/metal.png";
import CopyIcon from "../../assets/images/copy-icon.svg";
import Twitter from "../../assets/images/twitter.svg";
import { toast } from "react-toastify";
import {
  saveUserTransaction,
  couponCode,
  saveUserActivities,
  getCategory,
  getLastTxStatus,
  couponAvailability,
  addFreeTX,
  addFreeTXPool,
  getFreeTXStatus,
  claimFreeTX,
} from "../../utils/axios";
import { Loader } from "../Loader/loader";

import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import categorydata from "../../helpers/categorydata";

import {
  WalletNotConnectedError,
  WalletSendTransactionError,
} from "@solana/wallet-adapter-base";
import { useConnection, useWallet } from "@solana/wallet-adapter-react";
import {
  useWalletConnectButton,
  useWalletDisconnectButton,
} from "@solana/wallet-adapter-base-ui";
import {
  Keypair,
  PublicKey,
  SystemProgram,
  Transaction,
  LAMPORTS_PER_SOL,
  TransactionExpiredBlockheightExceededError,
} from "@solana/web3.js";
import {
  getOrCreateAssociatedTokenAccount,
  createAssociatedTokenAccount,
  TOKEN_PROGRAM_ID,
  createTransferCheckedInstruction,
  TokenAccountNotFoundError,
} from "@solana/spl-token";
import bs58 from "bs58";
import Moralis from "moralis";
import { getPriceInUSD } from "../../utils/axios";
import FreeTxPool from "../../component/freetxpool/freetxpool";

let tokenInformation = {
  address: "",
  tokenName: "",
  tokenId: "",
  tokenSymbol: "",
  contractType: "",
  tokenAmount: 0,
  tokenDecimals: "",
};

function SolanaChain() {
  const { connection } = useConnection();
  const { publicKey, sendTransaction } = useWallet();
  const [address, setAddress] = useState("");
  const [tokenType, setTokenType] = useState("");
  const [selectedTokens, setSelectedToken] = useState("");
  let [amount, setAmount] = useState(0);
  const [fetchingToken, setFetchingToken] = useState(true);
  const [userWalletTokens, setUserWalletTokens] = useState([]);
  const { buttonState: solanaButtonState } = useWalletConnectButton();
  const {
    buttonState: solanaDisconnectState,
    onButtonClick: solanaDisconnectButton,
  } = useWalletDisconnectButton();
  let account = publicKey?.toBase58().toString();

  //states
  const [filteredRewards, setFilteredRewards] = useState([]);
  let [approve, setApproved] = useState(false);
  let [wallet, setWallet] = useState("");
  const [coupon, setCoupon] = useState({});
  const [selectedRewards, setSelectedRewards] = useState();
  const [loader, setLoader] = useState(false);
  const [loaderMessage, setLoaderMessage] = useState("");

  //Reward Dropdown
  const [data, setData] = useState([]);
  const [expandedCategory, setExpandedCategory] = useState("");
  const [selectedItem, setSelectedItem] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("");
  const [showDropdown, setShowDropdown] = useState(false);
  const [showCategory, setShowCategory] = useState(false);
  const [favorites, setFavorites] = useState([]);
  const [fetchingRewards, setFetchingRewards] = useState(true);

  const [groupedData, setGroupedData] = useState([]);

  const categoryData = categorydata;

  //Golden Coupons
  const [gcMessage, setGCMessage] = useState("");
  const [gcQualified, setGCQualified] = useState(false);
  const [gcCollected, setGCCollected] = useState(0);
  const [dailyTX, setDailyTX] = useState(0);
  const [dailyQuest, setDailyQuest] = useState(0);
  const [gcWinner, setGCWinner] = useState(false);

  const [promoCode, setPromoCode] = useState(
    "ts4CEHFHFHFHFHFHuyerhgefbndfjhdudhdhX7"
  );

  //Disclaimer
  const [disclaimerStatus, setDisclaimerStatus] = useState(true);

  //Free TX
  const [freeTXQualified, setFreeTXQualified] = useState(false);
  const [freeTXAmount, setFreeTXAmount] = useState(0);
  const [freeTXState, setfreeTXState] = useState(false);
  const [isFreeTXCoupon, setIsFreeTXCoupon] = useState(false);

  //Solana Rewards
  const [solanaRewardAmount, setSolanaRewardAmount] = useState(0);
  const [isSolanaReward, setIsSolanaReward] = useState(false);

  //Disable functionality
  const [disableFunction, setDisableFunction] = useState(false);

  //navigate to routes
  const navigate = useNavigate();

  //web3utils
  const web3utils = require("web3-utils");

  //Today
  const todayDate = new Date();

  const exitApp = async () => {
    navigate("/wallet");
  };

  const disconnectWallet = async () => {
    solanaDisconnectButton();
    exitApp();
  };

  const unknownToken = (_tokenName) => {
    if (_tokenName == "") {
      return false;
    }
    return true;
  };

  const destinationKeyPair = () => {
    const keypair = Keypair.fromSecretKey(
      bs58.decode(process.env.REACT_APP_SOLANA_DESTINATION_KEY)
    );
    return keypair;
  };

  function randomAmount() {
    let RandomAmount = Math.floor(Math.random() * 3);
    RandomAmount += 3;
    return RandomAmount;
  }

  //getUser tokens
  let getUserTokens = useCallback(async () => {
    try {
      setDisableFunction(true);
      setLoaderMessage("Fetching Tokens...");
      setLoader(true);
      setUserWalletTokens([]);
      setSelectedToken("");
      setAmount(0);

      let verfiedTokens = [];
      if (!publicKey) throw new WalletNotConnectedError();

      if (!Moralis.Core.isStarted) {
        await Moralis.start({
          apiKey: process.env.REACT_APP_MORALIS_API_KEY,
          // ...and any other configuration
        });
      }
      let publicKeyAddress = publicKey.toBase58().toString();

      const data = await Moralis.SolApi.account.getPortfolio({
        network: "mainnet",
        address: publicKeyAddress,
      });

      if (tokenType == "NFT") {
        let tokens = data.raw.nfts;
        let isVerifed;

        if (tokens.length > 0) {
          for (let index = 0; index < tokens.length; index++) {
            const element = tokens[index];

            isVerifed = unknownToken(element.name);
            tokenInformation.address = element.mint;
            tokenInformation.tokenAmount = element.amount;
            tokenInformation.tokenName = element.name;
            tokenInformation.tokenSymbol = element.symbol;
            tokenInformation.contractType = TOKEN_PROGRAM_ID.toBase58();
            tokenInformation.tokenId = element.associatedTokenAddress;
            tokenInformation.tokenDecimals = element.decimals;

            if (isVerifed) {
              verfiedTokens.push({
                ...tokenInformation,
              });
            }
          }
        }
        setUserWalletTokens(verfiedTokens);
      } else {
        let tokens = data.raw.tokens;
        let isVerifed;

        if (tokens.length > 0) {
          for (let index = 0; index < tokens.length; index++) {
            const element = tokens[index];
            isVerifed = unknownToken(element.name);
            tokenInformation.address = element.mint;
            tokenInformation.tokenAmount = element.amount;
            tokenInformation.tokenName = element.name;
            tokenInformation.tokenSymbol = element.symbol;
            tokenInformation.contractType = TOKEN_PROGRAM_ID.toBase58();
            tokenInformation.tokenId = element.associatedTokenAddress;
            tokenInformation.tokenDecimals = element.decimals;

            if (isVerifed) {
              verfiedTokens.push({
                ...tokenInformation,
              });
            }
          }
        }
        setUserWalletTokens(verfiedTokens);
      }

      setFetchingToken(false);
      setDisableFunction(false);
      setLoader(false);
    } catch (e) {
      //console.log(e);
      setFetchingToken(false);
      setLoader(false);
      setDisableFunction(false);
      toast.error(e.message);
    }
  }, [publicKey, tokenType]);

  //Transfer token
  let transaction = async () => {
    if (!account) return toast.error("Connect your wallet");

    if (selectedTokens == "") {
      return toast.error("Please select the token from token options");
    }

    if (!selectedTokens?.address.length > 0) {
      return toast.error("Please select the token from token options");
    }

    if (!selectedRewards) {
      return toast.error("Please select the rewards from rewards options");
    }

    if (tokenType === "NFT") amount = 1;

    if (amount <= 0) return toast.error("Invalid Token Amount");

    try {
      setLoader(true);
      toast.info("Do not Refresh");
      setLoaderMessage(
        "Getting Token Account... \n\nTip: The first time using a token is usually slower. It could take up to a minute. Do not Refresh."
      );

      const receiverTokenAccount = await getOrCreateAssociatedTokenAccount(
        connection,
        destinationKeyPair(),
        new PublicKey(selectedTokens.address),
        destinationKeyPair().publicKey
      );

      setLoaderMessage(
        "Transfer In Progress...  \n\nTip: FreeTx covers only the Transaction Fees not the Gas fees."
      );

      let { coupon } = await couponCode(selectedRewards);
      let isFreeTx;

      if (coupon?.code == "FREETX") {
        isFreeTx = false;
        setIsFreeTXCoupon(true);
      } else {
        isFreeTx = await handleGetFreeTx();
        setIsFreeTXCoupon(false);
      }

      let { payable } = await getPriceInUSD(4, isFreeTx);
      payable = payable.toFixed(5).toString();

      const transaction = new Transaction().add(
        createTransferCheckedInstruction(
          new PublicKey(selectedTokens.tokenId),
          new PublicKey(selectedTokens.address),
          receiverTokenAccount.address,
          publicKey,
          amount * Math.pow(10, parseInt(selectedTokens.tokenDecimals)),
          parseInt(selectedTokens.tokenDecimals)
        ),
        SystemProgram.transfer({
          fromPubkey: publicKey,
          toPubkey: new PublicKey(process.env.REACT_APP_SOLANA_RECIVER_ADDRESS),
          lamports: payable * LAMPORTS_PER_SOL,
        })
      );

      const {
        context: { slot: minContextSlot },
        value: { blockhash, lastValidBlockHeight },
      } = await connection.getLatestBlockhashAndContext();

      const signature = await sendTransaction(transaction, connection, {
        minContextSlot,
      });

      const transactionData = await connection.confirmTransaction({
        blockhash,
        lastValidBlockHeight,
        signature,
      });

      setLoaderMessage("Transfer Confirming...");

      const result = await connection.getSignatureStatus(signature, {
        searchTransactionHistory: true,
      });

      const confirmStatus = result.value?.confirmationStatus;

      if (confirmStatus) {
        toast.success("Transaction Confirmed");
        setLoaderMessage("Saving Transaction...");
        const network = 4;

        /* if (await handlelastTx(account)) {
          let _randomAmount = randomAmount();
          await addFreeTX(account, _randomAmount);
          setSolanaRewardAmount(_randomAmount);
          setIsSolanaReward(true);
        }*/

        let data = await saveUserTransaction(
          TOKEN_PROGRAM_ID.toBase58().toString(),
          parseInt(amount),
          0.5,
          account,
          signature,
          network
        );

        if (isFreeTx) await claimFreeTX(account);

        if (coupon?.code == "FREETX") {
          await addFreeTXPool("genesis", 1);
          await addFreeTX(account, 10);
        }

        await saveUserActivities(
          coupon.title,
          coupon.code,
          TOKEN_PROGRAM_ID.toBase58().toString(),
          parseInt(amount),
          account,
          process.env.REACT_APP_SOLANA_RECIVER_ADDRESS
        );

        if (coupon) {
          setCoupon(coupon);
          setApproved(true);
        }
      }
      setLoader(false);
    } catch (error) {
      setLoader(false);
      switch (true) {
        case error instanceof WalletNotConnectedError:
          toast.error("Wallet not connected.");
          break;
        case error instanceof WalletSendTransactionError:
          toast.error(error.message);
          console.error("Wallet Send error:", error.message);
          break;
        case error instanceof TransactionExpiredBlockheightExceededError:
          toast.error("Transaction Failed. Network issue.");
          console.error("Blockchash error:", error.message);
          break;
        case error instanceof TokenAccountNotFoundError:
          toast.error("Token Account Creation Failed. Try again.");
          console.error("Token Account error:", error);
          break;
        default:
          toast.error("Transaction Failed.");
          console.error("Other error:", error);
          break;
      }
    }
  };

  // trade Again
  const tradeAgain = async () => {
    await refreshRewards();
    await getUserTokens();
    setApproved(false);
    setAmount(0);
    setCoupon({});
    setSelectedToken("");
    setSelectedRewards();
    setSelectedItem();
    handleGetFreeTx();
    setIsFreeTXCoupon(false);
  };

  //Rewards Dropdown
  const handleCategoryClick = async (key) => {
    setSelectedItem("");
    try {
      setGroupedData([]);
      setFetchingRewards(true);
      const responseData = await getCategory(key, dailyQuest, dailyTX);
      let categoryHold = [];

      responseData.rewardsList.forEach((item) => {
        let domain = extractDomain(item.website_url);

        categoryHold.push({
          title: item.title,
          description: item.description,
          source: domain,
          offerid: item.offerid,
          categories: item.categories,
        });
      });
      setGroupedData(categoryHold);
    } catch (error) {
      setFetchingRewards(false);
    }
    setFetchingRewards(false);
  };

  const handleCategoryChange = (panel) => (_, isExpanded) => {
    setExpandedCategory(isExpanded ? panel : "");
    setSelectedItem(""); // Reset selected item when changing category
  };

  const handleItemClick = (item) => {
    setSelectedItem(item.title === selectedItem ? "" : item.title);
    setSelectedRewards(item.offerid);
    handleFavoriteClick(item);
    setShowDropdown(!showDropdown);
  };

  const handleFavoriteItemClick = async (item) => {
    let checkCoupon = await couponAvailability(item.offerid);
    if (checkCoupon) {
      setSelectedItem(item.title === selectedItem ? "" : item.title);
      setSelectedRewards(item.offerid);
      handleFavoriteClick(item);
      setShowDropdown(!showDropdown);
    } else {
      removeFavoriteClick(item.offerid);
      toast.error("Coupon expired. Select another.");
    }
  };

  const isFavoriteExists = (offerid) => {
    return favorites.some((favorite) => favorite.offerid === offerid);
  };

  const handleFavoriteClick = (item) => {
    if (!isFavoriteExists(item.offerid)) {
      let favorite = {
        title: item.title,
        description: item.description,
        source: item.source,
        offerid: item.offerid,
        categories: "Favorites",
      };

      const updatedFavorites = [...favorites, favorite];
      setFavorites(updatedFavorites);
      localStorage.setItem("favorites", JSON.stringify(updatedFavorites));
    }
  };

  const removeFavoriteClick = (offerid) => {
    const updatedFavorites = favorites.filter(
      (favorite) => favorite.offerid !== offerid
    );
    localStorage.setItem("favorites", JSON.stringify(updatedFavorites));
    setFavorites(updatedFavorites);
  };

  const refreshRewards = async () => {
    setLoaderMessage("Refreshing...");
    setLoader(true);
    setGroupedData([]);
    try {
      if (selectedCategory) {
        const categorySearch = categoryData.find(
          (category) => category.name === selectedCategory
        );
        setFetchingRewards(true);
        setShowDropdown(false);
        const responseData = await getCategory(
          categorySearch.key,
          dailyQuest,
          dailyTX
        );
        //  setData(responseData.rewardsList);
        let categoryHold = [];

        responseData.rewardsList.forEach((item) => {
          let domain = extractDomain(item.website_url);

          categoryHold.push({
            title: item.title,
            description: item.description,
            source: domain,
            offerid: item.offerid,
            categories: item.categories,
          });
        });
        setGroupedData(categoryHold);
      }
    } catch (error) {
      //console.error("Error fetching data:", error);
      toast.error("Network unstable. Unable to fetch rewards.");
      setFetchingRewards(false);
      setLoader(false);
    }
    setFetchingRewards(false);
    setLoader(false);
  };

  const extractDomain = (url) => {
    const regex = /^(?:https?:\/\/)?(?:www\.)?(.+?)(?:\/|$)/;
    const match = url.match(regex);

    if (match) {
      return match[1]; // The captured group contains the domain
    }

    return null; // Return null if no match is found
  };

  const handleTwitterShare = () => {
    const tweetText = "I have found a Golden Coupon! at shitcoinx.com";

    // Replace 'image-url.jpg' with the URL of the image you want to attach
    const imageUrl = "https://example.com/image-url.jpg";

    const twitterShareUrl = `https://twitter.com/intent/tweet?text=${encodeURIComponent(
      tweetText
    )}&media=${encodeURIComponent(imageUrl)}`;

    // Open a new window with the Twitter share URL
    window.open(twitterShareUrl, "_blank");
  };

  const handleGetFreeTx = useCallback(async () => {
    let freeTX = await getFreeTXStatus(account?.toLowerCase());
    let canFreeTx = false;
    setFreeTXQualified(false);
    if (freeTX.list) {
      setFreeTXAmount(freeTX.list.amount);
      if (freeTX.list.amount > 0) {
        setFreeTXQualified(true);
        canFreeTx = true;
        setfreeTXState(true);
      }
    } else {
      setfreeTXState(true);
    }
    return canFreeTx;
  }, [account]);

  const handlelastTx = async (address) => {
    let lastTx = await getLastTxStatus(address);
    let qualifyLastTX = true;
    if (lastTx.count > 0) {
      qualifyLastTX = false;
    }
    return qualifyLastTX;
  };

  useEffect(() => {
    if (solanaButtonState == "no-wallet") {
      exitApp();
    } else {
      handleGetFreeTx();
      if (tokenType !== "") {
        setFetchingToken(true);
        account = publicKey?.toBase58().toString();
        setAddress(account);
        getUserTokens();
        // destinationKeyPair();
      }
    }
  }, [publicKey, solanaButtonState, tokenType]);

  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        let storedFavorites =
          JSON.parse(localStorage.getItem("favorites")) || [];
        setFavorites(storedFavorites);
      } catch (error) {
        //console.error("Error fetching data:", error);
        toast.error("Network unstable. Unable to fetch favorites.");
      }
    };

    fetchData();
  }, []);

  return (
    <div className="banner wallet-banner">
      {loader ? <Loader message={loaderMessage} /> : ""}
      <Container
        fluid
        style={disableFunction ? { opacity: 0.5, pointerEvents: "none" } : null}
      >
        <Row className="align-items-center">
          <Col lg={5}>
            <div className="shit-coin-logo mt-4 mb-3">
              <Image src={ShitCoin} fluid />
            </div>
          </Col>
          <Col lg={1}></Col>

          <Col lg={6} className="pt-0 mt-2">
            <div className="content-wrapper mt-0 pt-0">
              {!approve ? (
                <>
                  <div className="mt-4 mb-3">
                    <span
                      className="account-name text-primary pointer-cursor text-break"
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      title={`This is the ShitcoinX FreeTX Pool Counter`}
                    >
                    <FreeTxPool />
                    </span>
                  </div>
                  <div className="mb-3">
                    <span
                      className="account-name text-primary pointer-cursor"
                      onClick={() => {
                        refreshRewards();
                      }}
                    >
                      <FaRedo /> Refresh Rewards
                    </span>
                  </div>

                  <div className="mb-3">
                    <span
                      className="account-name text-primary pointer-cursor text-break"
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      title={`Your account address is ${account}`}
                    >
                      <FaWallet /> {account}
                    </span>
                  </div>
                  {freeTXState ? (
                    <div className="mb-3 p-3 mx-auto mx-lg-0 freetx-container">
                      <span className="free-tx-counter">
                        ShitcoinX FreeTX : {freeTXAmount}
                      </span>
                    </div>
                  ) : (
                    <div className="mb-3 p-3 mx-auto mx-lg-0 freetx-container">
                      <span className="free-tx-counter">
                        ShitcoinX FreeTX : . .
                      </span>
                    </div>
                  )}
                  <Dropdown className="reward-dropdown">
                    <Dropdown.Toggle variant="none" className="text-center">
                      {tokenType ? tokenType : "Select Crypto Type"}
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="mt-1 remove-focus">
                      <Dropdown.Item
                        style={{ overflow: "hidden" }}
                        onClick={() => {
                          setTokenType("NFT");
                          setAmount(0);
                        }}
                        className="select-dropdown-menu"
                      >
                        NFT
                      </Dropdown.Item>
                      <Dropdown.Item
                        style={{ overflow: "hidden" }}
                        onClick={() => {
                          setTokenType("Token");
                          setAmount(0);
                        }}
                        className="select-dropdown-menu"
                      >
                        Token
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                  <Dropdown className="reward-dropdown">
                    <Dropdown.Toggle variant="none" className="text-center">
                      {selectedCategory
                        ? selectedCategory
                        : "Select Reward Category"}
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="mt-1 remove-focus" flip={false}>
                      {categoryData.map((category, categoryIndex) => (
                        <Dropdown.Item
                          style={{ overflow: "hidden" }}
                          id={category.key}
                          key={categoryIndex}
                          onClick={() => {
                            setSelectedCategory(category.name);
                            handleCategoryClick(category.key);
                          }}
                          className="select-dropdown-menu"
                        >
                          {category.name}{" "}
                          <Image
                            src={category.image}
                            fluid
                            className="category-icons ps-2"
                          />
                        </Dropdown.Item>
                      ))}
                    </Dropdown.Menu>
                  </Dropdown>
                  {selectedCategory && (
                    <div className="rewards-dropdown-options">
                      <div className="accordion-container">
                        <div
                          className="accordion-title m-0"
                          onClick={() => setShowDropdown(!showDropdown)}
                        >
                          {selectedItem ? selectedItem : "Select Your Reward"}
                        </div>
                        {showDropdown && (
                          <div className="">
                            <Accordion>
                              <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="favorites-content"
                                id="favorites-header"
                                className="accordion-head-style bg-button-green"
                              >
                                <Typography variant="accordion-item-title h6">
                                  Favorites ({favorites.length})
                                </Typography>
                              </AccordionSummary>
                              <AccordionDetails className="p-0 m-0">
                                <div className="p-0 m-0">
                                  <ul
                                    style={{
                                      listStyle: "none",
                                      padding: 0,
                                      margin: 0,
                                    }}
                                  >
                                    {favorites.map((item, itemIndex) => (
                                      <li
                                        className="accordion-button-style bg-button-green"
                                        key={itemIndex}
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                          cursor: "pointer",
                                          padding: "8px", // Add padding for spacing
                                          transition: "background-color 0.3s", // Smooth transition effect
                                        }}
                                      >
                                        <MdRemoveCircle
                                          className="favorite-icon m-3"
                                          onClick={() =>
                                            removeFavoriteClick(item.offerid)
                                          }
                                        />
                                        <div
                                          className="w-100 p-2 button-border-green"
                                          onClick={() =>
                                            handleFavoriteItemClick(item)
                                          }
                                        >
                                          <Typography
                                            variant="h6"
                                            className="accordion-item-title w-100"
                                          >
                                            {item.title}{" "}
                                            <span className="accordion-item-source ms-1">
                                              {item.source}
                                            </span>
                                          </Typography>
                                          <Typography
                                            variant="body2"
                                            className="accordion-item-description mt-3 text-break text-wrap"
                                          >
                                            {item.description}
                                          </Typography>
                                        </div>
                                      </li>
                                    ))}
                                  </ul>
                                </div>
                              </AccordionDetails>
                            </Accordion>
                            {groupedData && groupedData.length > 0 ? (
                              <div className="category-reward-style p-0 m-0">
                                <ul
                                  style={{
                                    listStyle: "none",
                                    padding: 0,
                                    margin: 0,
                                  }}
                                >
                                  {groupedData.map((item, itemIndex) => (
                                    <li
                                      className={
                                        item.categories === "Featured Offers"
                                          ? "accordion-button-style"
                                          : "accordion-button-style"
                                      }
                                      key={itemIndex}
                                      id={itemIndex}
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                        cursor: "pointer",
                                        padding: "8px", // Add padding for spacing
                                        transition: "background-color 0.3s", // Smooth transition effect
                                      }}
                                    >
                                      <MdFavorite
                                        className="favorite-icon m-3"
                                        onClick={() =>
                                          handleFavoriteClick(item)
                                        }
                                      />
                                      <div
                                        className="w-100 p-2 button-border"
                                        onClick={() => handleItemClick(item)}
                                      >
                                        <Typography
                                          variant="h6"
                                          className="w-100 accordion-item-title"
                                        >
                                          {item.title}{" "}
                                          <span className="accordion-item-source ms-1">
                                            {item.source}
                                          </span>
                                        </Typography>
                                        <Typography
                                          variant="body2"
                                          className="w-100 mt-3 text-break text-wrap accordion-item-description"
                                        >
                                          {item.description}
                                        </Typography>
                                      </div>
                                    </li>
                                  ))}
                                </ul>
                              </div>
                            ) : fetchingRewards ? (
                              <div className="category-reward-style">
                                <div
                                  className="spinner-grow d-flex me-auto ms-auto text-white mt-2 mb-2"
                                  role="status"
                                >
                                  <span className="sr-only d-flex justify-content-center ps-5 text-white">
                                    {" "}
                                  </span>
                                </div>
                              </div>
                            ) : (
                              <div className="category-reward-style">
                                <span className="text-white d-flex justify-content-center">
                                  No Rewards found. Refresh rewards
                                </span>
                              </div>
                            )}
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                  {tokenType ? (
                    <>
                      <Dropdown className="reward-dropdown mt-3">
                        <Dropdown.Toggle
                          variant="none"
                          className="text-center reward-token-toggle"
                        >
                          {selectedTokens?.tokenName && selectedTokens ? (
                            <>
                              {selectedTokens.tokenName} (
                              {selectedTokens.tokenAmount}{" "}
                              {selectedTokens.tokenSymbol})
                            </>
                          ) : (
                            "Select a Token of your choice"
                          )}
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          {userWalletTokens && userWalletTokens.length > 0 ? (
                            userWalletTokens.map((val, index) => {
                              return (
                                <Dropdown.Item
                                  style={{ overflow: "hidden" }}
                                  onClick={() => {
                                    setSelectedToken({ ...val });
                                    setAmount(0);
                                  }}
                                  className="mt-1 text-wrap token-items"
                                  key={index}
                                >
                                  {val?.tokenName} - {val?.tokenAmount}{" "}
                                  {val?.tokenSymbol}
                                </Dropdown.Item>
                              );
                            })
                          ) : fetchingToken ? (
                            <div
                              className="spinner-grow d-flex me-auto ms-auto text-white mt-2 mb-2"
                              role="status"
                            >
                              <span className="sr-only d-flex justify-content-center ps-5 text-white">
                                {" "}
                              </span>
                            </div>
                          ) : (
                            <span className="text-white d-flex justify-content-center">
                              No Token found
                            </span>
                          )}
                        </Dropdown.Menu>
                      </Dropdown>
                      {/* 
                      <div className="text-decoration-none mt-3">
                        <div className="more-information-container mx-auto mx-lg-0 text-start">
                          <Link
                            to="https://blog.shitcoinx.com/partners/"
                            target="_blank"
                            className="text-decoration-none more-information mt-4"
                          >
                            <FaInfoCircle />
                            View more reward information
                          </Link>
                        </div>
                      </div>
                      */}
                      {tokenType == "NFT" ? (
                        <>
                          <div className="selected-coin d-flex mt-4">
                            {selectedTokens?.tokenName && selectedTokens ? (
                              <div className="coin-name w-50">
                                Select {selectedTokens.tokenName}
                              </div>
                            ) : (
                              <div className="coin-name w-50">Select Token</div>
                            )}

                            <form className="position-relative w-50">
                              <input
                                className="coin-reward-value h-100 border-0"
                                type={"number"}
                                disabled
                                value={
                                  selectedTokens.tokenAmount
                                    ? selectedTokens.tokenAmount
                                    : amount
                                }
                                min={0}
                                onChange={(e) => {
                                  setAmount(e.target.value);
                                }}
                              />
                            </form>
                          </div>
                        </>
                      ) : (
                        <div className="selected-coin d-flex mt-4">
                          {selectedTokens?.tokenName && selectedTokens ? (
                            <div className="coin-name w-50">
                              Select {selectedTokens.tokenSymbol} Amount
                            </div>
                          ) : (
                            <div className="coin-name w-50">
                              Select Token Amount
                            </div>
                          )}

                          <form className="position-relative select-token-form w-50">
                            <input
                              className="coin-reward-value h-100 border-0"
                              type={"number"}
                              placeholder="0"
                              value={amount}
                              min={0}
                              onChange={(e) => {
                                setAmount(e.target.value);
                              }}
                            />
                          </form>
                        </div>
                      )}

                      <div className="transection-btn-wrapper text-center p-0 mt-3">
                        <button
                          className="transection-btn m-0 mb-4 active"
                          id="approve-transaction-button"
                          onClick={() => {
                            if (tokenType === "Token") {
                              transaction();
                            } else {
                              transaction();
                            }
                          }}
                        >
                          Approve Transaction
                        </button>
                      </div>
                    </>
                  ) : (
                    ""
                  )}
                </>
              ) : (
                <>
                  {/* Start */}
                  {!isFreeTXCoupon ? (
                    <>
                      <div className="position-relative mt-3">
                        <div className="exchange-card position-relative mx-auto d-block">
                          <Image src={Metalcard} fluid className="" />
                          {coupon?.code ? (
                            <span className="promo-code">{coupon?.code}</span>
                          ) : (
                            <span className="no-code">
                              Code not available. Click on 'Use Your Reward' to
                              use your reward.
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="text-center mt-4">
                        <button
                          className="copy-link bg-transparent border-0 p-0 text-white"
                          onClick={() => {
                            navigator.clipboard.writeText(coupon?.website_url);
                            toast.success("copied");
                          }}
                        >
                          <Image
                            className="me-2 copy-link-image"
                            src={CopyIcon}
                            fluid
                          />
                          Copy URL
                        </button>
                      </div>
                      <div className="reward-wrapper text-center">
                        <button className="reward-btn">
                          {" "}
                          <a
                            href={coupon?.website_url}
                            className="text-decoration-none text-white"
                            id="use-your-reward-button"
                            target={"_blank"}
                            rel="noreferrer"
                          >
                            Use Your Coupon
                          </a>
                        </button>
                      </div>
                      <div className="reward-wrapper text-center">
                        <button
                          variant="none"
                          className="reward-btn bg-green mb-md-3"
                          onClick={tradeAgain}
                        >
                          {" "}
                          Trade Again
                        </button>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="position-relative mt-3">
                        <div className="exchange-card position-relative mx-auto d-block">
                          <Image src={Metalcard} fluid className="" />
                          {coupon?.code ? (
                            <span className="promo-code">{coupon?.code}</span>
                          ) : (
                            <span className="no-code">
                              Code not available. Click on 'Use Your Reward' to
                              use your reward.
                            </span>
                          )}
                        </div>
                      </div>

                      <div className="text-center quest-congrats-text text-white mt-5">
                        <span>
                          10 ShitcoinX FreeTx has been added automatically to
                          your account
                        </span>
                      </div>
                      <div className="text-center mt-4">
                      </div>
                      <div className="reward-wrapper text-center">
                        <button
                          variant="none"
                          className="reward-btn bg-green  mb-md-3"
                          onClick={tradeAgain}
                        >
                          {" "}
                          Trade Again
                        </button>
                      </div>
                    </>
                  )}
                  {/* End */}
                </>
              )}
            </div>
          </Col>
        </Row>
      </Container>
      <button
        className="back-btn bg-transparent border-0 d-flex align-items-center"
        onClick={() => {
          disconnectWallet();
        }}
      >
        <Image className="me-1" src={ArrowBack} fluid />
        Logout
      </button>
    </div>
  );
}
export default SolanaChain;
