import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import Web3ConnectProvider from "./contexts/Web3Connect";
import PlenaConnectProvider from "./contexts/PlenaWalletContext";
import WagmiProvider from "./contexts/WagmiContext";
import { WagmiConfig } from "wagmi";
import { wagmiconfig } from "./utils/connectors";
import "react-toastify/dist/ReactToastify.css";
import { SolanaWallet } from "./contexts/SolanaWalletContext";
import { PlenaWalletProvider } from "plena-connect-dapp-sdk";

const plenaconfig = {
  dappToken:
    "91651531fc0ff6f89808b72c7ca0fcda6a9816a225e33f4b226e5bfdadccf776007dee0a61aa8bfd8f32ceed5c3374da4b820f51b1dd1829c441aaa4eee83891",
  dappId: "cm61ds5dotv8m80olbig",
  bridgeUrl: "connect.plena.finance",
};

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <WagmiConfig config={wagmiconfig}>
    <Web3ConnectProvider>
      <PlenaWalletProvider config={plenaconfig}>
        <PlenaConnectProvider>
          <SolanaWallet>
            <BrowserRouter>
              <App />
            </BrowserRouter>
          </SolanaWallet>
        </PlenaConnectProvider>
      </PlenaWalletProvider>
    </Web3ConnectProvider>
  </WagmiConfig>
);
