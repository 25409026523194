import { createContext, useContext, useState, useEffect } from "react";

// hooks
import useSwitch from "../hooks/useSwitch";
import useForceUpdate from "../hooks/useForceUpdate";
import usePersistentToast from "../hooks/usePersistentToast";

// libraries
import { toast } from "react-toastify";
import {
  Web3ReactProvider,
  useWeb3React,
  UnsupportedChainIdError,
} from "@web3-react/core";
import { UserRejectedRequestError } from "@web3-react/injected-connector";
import { Web3Provider } from "@ethersproject/providers";
import detectEthereumProvider from '@metamask/detect-provider';

// helpers
import Web3 from "../helpers/web3";
import {
  activateInjectedProvider,
  returnInjectedProvider,
} from "../helpers/activateinjectedprovider";
import {useAccount, useDisconnect} from 'wagmi';




// config
import { allowedChains } from "../utils/config";

// axios
import { userWallet, removeWallet, setProvider } from "../utils/axios";

//connectors
import { injected, CoinbaseWallet, walletconnect } from "../utils/connectors";

const { ethereum } = window;

const WagmiContext = createContext({
  account: "",
  chain: "",
  disconnect: () => {},
  connect: () => {},
  isConnectedToAllowedNetwork: async () => false,
  handleTransactionError: (err) => {},
  refresh: {
    rerender: () => {},
    triggerValue: 0,
  },
});

export const useWagmi = () => useContext(WagmiContext);

const isConnectedToAllowedNetwork = async () => {
  let eth_provider = window.ethereum;

  let provider = localStorage.getItem("Provider");

  eth_provider = returnInjectedProvider(provider);

  if(eth_provider === undefined) eth_provider = window.ethereum;

  let chainID = parseInt(await eth_provider.request({ method: "eth_chainId" }));

  return !(
    allowedChains.length > 0 &&
    !allowedChains.find((chain) => chain.id === chainID)
  );
};

const WagmiProvider = ({ children }) => {
  const forceUpdate = useForceUpdate();
  const [account, setAccount] = useState("");
  const [chain, setChain] = useState();
  const isTransactionErrorModalOpen = useSwitch();
  const { active, activate, deactivate, error } = useWeb3React();
  const { address, connector } = useAccount();
  //const { disconnect } = useDisconnect();
  //  let provider = window.ethereum;

  const persistentSwitchChainToast = usePersistentToast(
    "Please connect to one of the supported chains",
    "error"
  );

  const persistentWeb3BrowserToast = usePersistentToast(
    "Ensure you are using a Web3 enabled browser",
    "error"
  );

  let isConnected = async () => {
    let eth_provider = window.ethereum;

    let provider = localStorage.getItem("Provider");

    eth_provider = returnInjectedProvider(provider);

    if(eth_provider=== undefined) eth_provider = window.ethereum;

    const accounts = await eth_provider.request({ method: "eth_accounts" });

    if (accounts.length) {
      setAccount(accounts[0]);
      return true;
    } else {
      return false;
    }
  };

  const connect = async (chainId, wallettype) => {
    switch (wallettype) {
      case "metamask":
        try {
          let provider = window.ethereum;

          if (typeof window.ethereum !== "undefined") {
            activateInjectedProvider("MetaMask");

            provider = returnInjectedProvider("MetaMask");

            if(provider === undefined) provider = window.ethereum;

            if (![chainId].includes(allowedChains)) {
              await provider.request({
                method: "wallet_switchEthereumChain",
                params: [{ chainId: `0x${chainId.toString(16)}` }],
              });
            }

            const accounts = await provider.request({
              method: "eth_requestAccounts",
              params: [],
            });

            setProvider("MetaMask");

            setAccount(accounts[0]);
          } else if ("ethereum#initialized") {
            window.location = process.env.REACT_APP_METAMASK_URL;
            await ethereum.request({
              method: "wallet_switchEthereumChain",
              params: [{ chainId: `0x${chainId.toString(16)}` }],
            });
          }

          const chain = parseInt(
            await provider.request({ method: "eth_chainId" })
          );

          setChain(chain);
        } catch (e) {
          switch (e.code) {
            case 4001:
              toast.info("Please connect to Metamask");
              break;
            case -32002:
              toast.info("Please open Metamask");
              break;
            case e:
              toast.info("Please switch to BSC network");
              break;
            default:
          }
        }
        break;

      case "coinbase":
        try {
          let provider = window.ethereum;

          activateInjectedProvider("CoinBase");

          provider = returnInjectedProvider("CoinBase");

          if(provider === undefined) provider = window.ethereum;

          //console.log("Wallet connect triggered");

          //await activate(injected, undefined, true);
          await injected.connect(chainId);

         // console.log(provider);

          await CoinbaseWallet.connect(chainId);

        //  console.log("clicked 1");

          const accountCoinbase = await CoinbaseWallet.getAccount();
          const chainIdCoinbase  = await CoinbaseWallet.getChainId();
          const providerAcc = await CoinbaseWallet.getProvider();
          // setConnectorName("Wallet Connect");
          // setWcAccount(accountWC);
          // setWcChainId(chainIdWC);
          setProvider("CoinBase");
        //  setAccount(accounts[0]);
          setChain(chainIdCoinbase);
          
         // console.log(providerAcc);


          if (![chainId].includes(allowedChains)) {
            console.log("Entered");
            await provider.request({
              method: "wallet_switchEthereumChain",
              params: [{ chainId: `0x${chainId.toString(16)}` }],
            });
             console.log("Exited");
          }


        } catch (e) {
          switch (e.code) {
            case 4001:
              toast.info("Please connect to Coinbase Wallet");
              break;
            case -32002:
              toast.info("Please open Coinbase Wallet");
              break;
            case e:
              toast.info("Please switch to the right network");
              break;
            default:
          }
        }
        break;

      case "walletconnect":
        try {
            let providerWallet = await walletconnect.connect(chainId);
            const accountWC = await walletconnect.getAccount();
            const chainIdWC = await walletconnect.getChainId();
            setProvider("WalletConnect");
            setChain(chainIdWC);
            setAccount(accountWC);
            console.log(providerWallet);
            console.log(connector);



        } catch (e) {
          switch (e.code) {
            case 4001:
              toast.info("Please connect to Walletconnect Wallet");
              break;
            case -32002:
              toast.info("Please open Walletconnect Wallet");
              break;
            case e:
              toast.info("Please switch to the right network");
              break;
            case e instanceof UserRejectedRequestError:
              toast.info("User rejected wallet.");
              break;
            default:
          }
        }
        break;

      default:
    }
  };

  const disconnect = async () => {
    await walletconnect.disconnect();
    deactivate();
    setAccount("");
    setChain("");
    removeWallet();
    forceUpdate.rerender();
  };

  const refresh = async () => {
    forceUpdate.rerender();
    let eth_provider = window.ethereum;

    let provider = localStorage.getItem("Provider");

    eth_provider = returnInjectedProvider(provider);

    if(eth_provider=== undefined) eth_provider = window.ethereum;

    const chain = parseInt(
      await eth_provider.request({ method: "eth_chainId" })
    );

    setChain(chain);
    if (await isConnectedToAllowedNetwork())
      return persistentSwitchChainToast.dismiss();
    persistentSwitchChainToast.trigger();
  };

  const handleTransactionError = (err) => {
    const fallbackMessage = `Something went wrong. Please check the transaction in the explorer.`;

    switch (err.code) {
      case 4001:
        toast.error("Transaction was rejected by the user.");
        return;

      default:
        if (err.message) {
          try {
            const substring = err.message.substring(
              err.message.indexOf("{"),
              err.message.lastIndexOf("}") + 1
            );
            const errorObject = JSON.parse(substring);
            const errorMessage =
              errorObject.originalError?.message || errorObject.value?.message;
            return toast.error(
              errorMessage.charAt(0).toUpperCase() +
                errorMessage.substr(1, errorMessage.length - 1)
            );
          } catch (error) {
            isTransactionErrorModalOpen.true();
          }
        } else {
          toast.error(fallbackMessage);
          return;
        }
    }
  };

  useEffect(() => {
    const init = async () => {

        console.log("clicked effect");

      let eth_provider = window.ethereum;

       console.log(window.ethereum);

       let testp = await detectEthereumProvider();

       console.log(testp);

       console.log(window.web3);

       const textc = connector;
      
       console.log(textc);

      let provider = localStorage.getItem("Provider");

      if (provider != undefined) {

        eth_provider = returnInjectedProvider(provider);

        if(eth_provider=== undefined) eth_provider = window.ethereum;

        let chainID = parseInt(
          await eth_provider.request({ method: "eth_chainId" })
        );

        setChain(chainID);

        if (!Web3.isEnabled()) return persistentWeb3BrowserToast.trigger();
        if (!(await isConnectedToAllowedNetwork()))
          persistentSwitchChainToast.trigger();
        isConnected();
        ethereum.on("chainChanged", refresh);
        ethereum.on("accountsChanged", (accounts) => {
          setAccount(accounts[0] || "");
          //  setChain(chainID);
          if (accounts[0]) {
            toast.success("Wallet is connected");
          } else {
            toast.info("Account is disconnected");
          }
        });
      } else {
        disconnect();
      }
    };
    init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (account.length > 0) {
      userWallet(account);
    } else {
    }
  }, [account]);

  const value = {
    account,
    connect,
    chain,
    disconnect,
    isConnectedToAllowedNetwork,
    handleTransactionError,
    refresh: { rerender: refresh, triggerValue: forceUpdate.triggerValue },
  };

  return (
    <>
      <WagmiContext.Provider value={value}>
        {children}
      </WagmiContext.Provider>
    </>
  );
};

export default WagmiProvider;
