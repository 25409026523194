import React, { useEffect } from "react";
import { Image } from "react-bootstrap";
import ShitCoin from "../../assets/images/shitcoinx.png";
import PartnerContent from "./PartnerContent";
import "./PartnerPage.css";
function RewardPage() {
  useEffect(() => {
    window.scroll(0, 0);
  }, []);
  return (
    <>
      <section className="partner-banner">
        <div className="partner-banner-content text-center">
          <div className="partner-banner-logo">
            <Image src={ShitCoin} fluid />
          </div>
        </div>
      </section>
      <PartnerContent />
    </>
  );
}

export default RewardPage;
