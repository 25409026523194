import { createContext, useContext, useState, useEffect } from "react";

// hooks
import useSwitch from "../hooks/useSwitch";
import useForceUpdate from "../hooks/useForceUpdate";
import usePersistentToast from "../hooks/usePersistentToast";

// libraries
import { toast } from "react-toastify";

// helpers
import Web3 from "../helpers/web3";

// config
import { allowedChains } from "../utils/config";

// axios
import { userWallet } from "../utils/axios";

const { ethereum } = window;

const MetamaskContext = createContext({
  account: "",
  chain: "",
  disconnect: () => {},
  connect: () => {},
  isConnectedToAllowedNetwork: async () => false,
  handleTransactionError: (err) => {},
  refresh: {
    rerender: () => {},
    triggerValue: 0,
  },
});

export const useMetamask = () => useContext(MetamaskContext);

const isConnectedToAllowedNetwork = async () => {
  const chainId = parseInt(await ethereum?.request({ method: "eth_chainId" }));
  return !(
    allowedChains.length > 0 &&
    !allowedChains.find((chain) => chain.id === chainId)
  );
};

const MetamaskProvider = ({ children }) => {
  const forceUpdate = useForceUpdate();
  const [account, setAccount] = useState("");
  const [chain, setChain] = useState();
  const isTransactionErrorModalOpen = useSwitch();

  const persistentSwitchChainToast = usePersistentToast(
    "Please connect to one of the supported chains",
    "error"
  );

  const persistentWeb3BrowserToast = usePersistentToast(
    "Ensure you are using a Web3 enabled browser",
    "error"
  );
  let isConnected = async () => {
    const accounts = await ethereum.request({ method: "eth_accounts" });
    if (accounts.length) {
      setAccount(accounts[0]);
      return true;
    } else {
      return false;
    }
  };

  const connect = async (chainId) => {
    try {
      if (window.ethereum) {
        if (!Web3.isEnabled()) return persistentWeb3BrowserToast.trigger();
        // if (await isConnected()) return;

        if (![chainId].includes(allowedChains)) {
          await ethereum.request({
            method: "wallet_switchEthereumChain",
            params: [{ chainId: `0x${chainId.toString(16)}` }],
          });
        }
        const accounts = await ethereum.request({
          method: "eth_requestAccounts",
        });
        setAccount(accounts[0]);
        const chain1 = parseInt(
          await ethereum.request({ method: "eth_chainId" })
        );
        setChain(chain1);
      } else if ("ethereum#initialized") {
        window.location = process.env.REACT_APP_METAMASK_URL;
        await ethereum.request({
          method: "wallet_switchEthereumChain",
          params: [{ chainId: `0x${chainId.toString(16)}` }],
        });
      }
      const accounts = await ethereum.request({
        method: "eth_requestAccounts",
      });
      setAccount(accounts[0]);
      const chain = parseInt(await ethereum.request({ method: "eth_chainId" }));
      setChain(chain);
    } catch (e) {
      switch (e.code) {
        case 4001:
          toast.info("Please connect to Metamask");
          break;
        case -32002:
          toast.info("Please open Metamask");
          break;
        case e:
          toast.info("Please switch to BSC network");
          break;
        default:
      }
    }
  };

  const disconnect = () => {
    setAccount("");
    setChain("");
    forceUpdate.rerender();
  };

  const refresh = async () => {
    forceUpdate.rerender();
    const chain = parseInt(await ethereum.request({ method: "eth_chainId" }));
    setChain(chain);
    if (await isConnectedToAllowedNetwork())
      return persistentSwitchChainToast.dismiss();
    persistentSwitchChainToast.trigger();
  };

  const handleTransactionError = (err) => {
    const fallbackMessage = `Something went wrong. Please check the transaction in the explorer.`;

    switch (err.code) {
      case 4001:
        toast.error("Transaction was rejected by the user.");
        return;

      default:
        if (err.message) {
          try {
            const substring = err.message.substring(
              err.message.indexOf("{"),
              err.message.lastIndexOf("}") + 1
            );
            const errorObject = JSON.parse(substring);
            const errorMessage =
              errorObject.originalError?.message || errorObject.value?.message;
            return toast.error(
              errorMessage.charAt(0).toUpperCase() +
                errorMessage.substr(1, errorMessage.length - 1)
            );
          } catch (error) {
            isTransactionErrorModalOpen.true();
          }
        } else {
          toast.error(fallbackMessage);
          return;
        }
    }
  };

  useEffect(() => {
    const init = async () => {
      let chainID = parseInt(await ethereum.request({ method: "eth_chainId" }));
      setChain(chainID);
      if (!Web3.isEnabled()) return persistentWeb3BrowserToast.trigger();
      if (!(await isConnectedToAllowedNetwork()))
        persistentSwitchChainToast.trigger();
      isConnected();
      ethereum.on("chainChanged", refresh);
      ethereum.on("accountsChanged", (accounts) => {
        setAccount(accounts[0] || "");
        setChain(chainID);
        if (accounts[0]) {
          toast.success("MetaMask is connected");
        } else {
          toast.info("Account is disconnected");
        }
      });
    };
    init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (account.length > 0) {
      userWallet(account);
    } else {
    }
  }, [account]);
  const value = {
    account,
    connect,
    chain,
    disconnect,
    isConnectedToAllowedNetwork,
    handleTransactionError,
    refresh: { rerender: refresh, triggerValue: forceUpdate.triggerValue },
  };

  return (
    <>
      <MetamaskContext.Provider value={value}>
        {children}
      </MetamaskContext.Provider>
    </>
  );
};

export default MetamaskProvider;
