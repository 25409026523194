import React, { useState } from "react";
import { Container, Row, Col, Image, Button, Modal } from "react-bootstrap";
import ShitCoinGif from "../../../assets/images/shitcoin.gif";
import Discord from "../../../assets/images/discord-green.svg";
import Twitter from "../../../assets/images/twitter-green.svg";
import Telegram from "../../../assets/images/telegram-green.svg";
import "./HeroSection.css";
import Quest from "../Quest/Quest";
function HeroSection() {
  return (
    <>
      <div className="home-banner">
        <div className="banner">
          <Container fluid className="custom-container position-relative mt-3">
            <Row className="align-items-center justify-content-between pb-0">
              <div className="banner-text-link-container px-1 px-md-0 mb-3 justify-content-center align-items-center text-end">
                <a
                  href="https://shitcoinx.gitbook.io/docs/"
                  target="_blank"
                  className="banner-text-link mx-auto m-0 p-0 text-center text-decoration-none"
                >
                  <button className="banner-text-link-button m-2" id="check-docs-button">
                    Docs
                  </button>
                </a>
              </div>
              <Col lg={5} className="mt-1 mt-md-2 mb-3">
                <div className="shit-coin-logo">
                  <Image src={ShitCoinGif} fluid />
                </div>
              </Col>
              <Col lg={7} className="p-0 p-lg-5 mt-sm-2 mt-0">
                <div className="mt-0 pb-0 m-0">
                  <h1 className="welcome-heading text-uppercase text-center text-lg-start">
                    ShitcoinX Token <br />
                    Exchange
                  </h1>

                  <div className="banner-text-container px-1 px-md-0 text-center text-lg-start">
                    <h6 className="banner-text mt-2 mt-sm-1 mx-auto mx-lg-0 px-3 px-md-0">
                      Exchange Crypto Tokens & NFTs for Shopping Coupons.
                      Redeemable in over 10,000 e-stores.
                    </h6>
                  </div>
                  <div className="mt-2 mt-md-4 mb-2 pb-0">
                    <button
                      className="wallet-btn mb-0 mx-sm-auto mx-md-auto mx-lg-0"
                      id="launch-app-button"
                      onClick={() => (window.location.href = "/wallet")}
                    >
                      Launch App
                    </button>
                  </div>
                  {/* 
                  <div className="banner-text-link-container px-1 px-md-0 mb-5">
                    <div className="wallet-btn wallet-btn-link mx-sm-auto mx-md-auto mx-lg-0">
                      <a
                        href="https://discord.gg/8UwgEUzVKR"
                        target="_blank"
                        className="banner-text-link mx-auto m-0 p-0 text-center text-decoration-none"
                        id="cta-banner-discord"
                      >
                        <Image
                          src={Discord}
                          fluid
                          className="banner-icons text-green"
                        />
                      </a>
                      <a
                        href="https://twitter.com/ShitCoinXchange"
                        target="_blank"
                        className="banner-text-link mx-auto m-0 p-0 text-center text-decoration-none"
                        id="cta-banner-twitter"
                      >
                        <Image
                          src={Twitter}
                          fluid
                          className="banner-icons text-green ms-4"
                        />
                      </a>
                      <a
                        href="https://t.me/shitcoinsexchange"
                        target="_blank"
                        className="banner-text-link mx-auto m-0 p-0 text-center text-decoration-none"
                        id="cta-banner-telegram"
                      >
                        <Image
                          src={Telegram}
                          fluid
                          className="banner-icons text-green ms-4"
                        />
                      </a>
                    </div>
                  </div>
                  */}
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <Quest />
      </div>
    </>
  );
}

export default HeroSection;
