import React, { useEffect } from "react";
import { Col, Container, Row} from "react-bootstrap";
import "./TermsCondition.css";

function TermsConditions() {
  useEffect(() => {
    window.scroll(0, 0);
  }, []);
  return (
    <div className="tc-detail text-center">
      <Container fluid className="custom-container">
        <Row>
          <Col className="mx-auto" lg={10}>
            <h1 className="tc-header">Terms & Conditions for<br /> ShitCoinX Crypto Token Exchange Service.</h1>
            <div className="tc-text-container">
              <h3 className="text-start">Introduction </h3>
              <p style={{ textAlign: "justify" }}>
                Welcome to the ShitcoinX Token Exchange Service ("Service"),
                which allows you to exchange certain worthless crypto tokens for
                discount coupons for online shopping sites. This Service is
                offered by ShitocinX (in partnership with CoinMarketAlert.com) a
                Swiss corporation (“Company”, “we”, “us”, or “our”).
              </p>
            </div>
            <div className="tc-text-container">
              <h3 className="text-start">Eligibility </h3>
              <p style={{ textAlign: "justify" }}>
                The Service is open to individuals who are at least 18 years
                old. By using the Service, you represent and warrant that you
                meet these eligibility requirements.
              </p>
            </div>
            <div className="tc-text-container">
              <h3 className="text-start">Eligible Tokens for Exchange</h3>
              <p style={{ textAlign: "justify" }}>
                The Service facilitates the exchange of worthless crypto tokens
                that are stored in the users' self-custodied wallets, in
                exchange for discount coupons for other products and services.
                The choice of which tokens to exchange lies solely with the
                user, and the Company does not make any determinations or
                suggestions regarding which tokens are deemed worthless.
              </p>
            </div>
            <div className="tc-text-container">
              <h3 className="text-start">Coupon redemption</h3>
              <p style={{ textAlign: "justify" }}>
                The discount coupons obtained through the Service can only be
                redeemed on the online shopping sites specified by the Company.
                The coupons are subject to the terms and conditions of the
                respective online shopping site and the Company makes no
                representations or warranties with respect to the coupons.
              </p>
            </div>
            <div className="tc-text-container">
              <h3 className="text-start">Limitation of Liability</h3>
              <p style={{ textAlign: "justify" }}>
                The Company will not be liable for any damages, including
                without limitation, direct, indirect, incidental, special,
                consequential, or punitive damages, arising out of or in
                connection with the use of the Service or the coupons obtained
                through the Service.
              </p>
            </div>
            <div className="tc-text-container">
              <h3 className="text-start">Indemnification</h3>
              <p style={{ textAlign: "justify" }}>
                You agree to indemnify and hold the Company and its affiliates,
                officers, agents, and employees harmless from any claim or
                demand, including reasonable attorneys' fees, made by any third
                party due to or arising out of your use of the Service.
              </p>
            </div>
            <div className="tc-text-container">
              <h3 className="text-start">Changes to Terms and Conditions</h3>
              <p style={{ textAlign: "justify" }}>
                The Company reserves the right to modify these Terms and
                Conditions at any time, without prior notice. Your continued use
                of the Service following any changes to these Terms and
                Conditions constitutes your acceptance of such changes.
              </p>
            </div>
            <div className="tc-text-container">
              <h3 className="text-start">Termination</h3>
              <p style={{ textAlign: "justify" }}>
                The Company may, in its sole discretion, at any time terminate
                or suspend all or a portion of the Service, or your use of all
                or a portion of the Service, with or without notice and with or
                without cause.
              </p>
            </div>
            <div className="tc-text-container">
              <h3 className="text-start">Governing Law</h3>
              <p style={{ textAlign: "justify" }}>
                These Terms and Conditions shall be governed by and construed in
                accordance with the laws of Zurich, and any applicable federal
                laws of Switzerland.
              </p>
            </div>
            <div className="tc-text-container">
              <h3 className="text-start">Dispute Resolution</h3>
              <p style={{ textAlign: "justify" }}>
                Any dispute arising out of or in connection with these Terms and
                Conditions or the Service shall be resolved through binding
                arbitration in accordance with the Commercial Arbitration Rules
                of the American Arbitration Association. The arbitration shall
                be conducted in Zurich, Switzerland.. The decision of the
                arbitrator shall be final and binding on the parties, and may be
                entered and enforced in any court of competent jurisdiction.
              </p>
            </div>
            <div className="tc-text-container">
              <h3 className="text-start">Entire Agreement</h3>
              <p style={{ textAlign: "justify" }}>
                These Terms and Conditions, together with the Privacy Policy,
                constitute the entire agreement between you and the Company with
                respect to the use of the Service. If any provision of these
                Terms and Conditions is found to be invalid or unenforceable,
                the remaining provisions shall remain in full force and effect.
              </p>
            </div>
            <div className="tc-text-container">
              <h3 className="text-start">Contact Information </h3>
              <p style={{ textAlign: "justify" }}>
                {" "}
                If you have any questions or concerns about the Service or these
                Terms and Conditions, please contact us at hello@shitcoinx.com.
                By using the Service, you acknowledge that you have read and
                understood these Terms and Conditions and agree to be bound by
                them.
              </p>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default TermsConditions;
